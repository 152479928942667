import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import Chart from 'chart.js/auto';
import ChartjsPluginStacked100 from "chartjs-plugin-stacked100";

console.log(process.env.REACT_APP_VERSION)

Chart.register(ChartjsPluginStacked100);

ReactDOM.render(<App />, document.getElementById("root"));

reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
