import React from 'react'
import {
    useEditController,
    useTranslate,
    SimpleForm,
    refreshView,
} from 'react-admin'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import {makeStyles} from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import {connect} from 'react-redux'
import {EditToolBar} from "./EditToolBar";
import {Box} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: '100vw',
        width: 500,
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '12px 24px 0 24px',
    },
    plane: {
        padding: '0 8px'
    },
    inlineField: {
        display: 'inline-block',
        width: '50%',
    },
    form: {
        padding: '0 8px'
    }
}))

export const EditController = ({...props}) => {
    const {baseURL, id, resource, editFormFields, onCancel, resourceName, editLabel} = props

    const classes = useStyles()
    const translate = useTranslate()
    const redirectURL = baseURL

    const controllerProps = useEditController({
        ...props,
        basePath: `${baseURL}/${resourceName}/${id}`,
        resource,
        id,
        undoable: false,
    })

    if (!controllerProps.record) {
        return null
    }

    const save = controllerProps.save
    controllerProps.save = (data, redirectTo, _a = {}) => save(data, redirectURL, _a)

    return (
        <div className={classes.root}>
            <div className={classes.title}>
                <Typography variant="h6">
                    {editLabel ? editLabel : `${translate('ra.action.edit')} ${resourceName}`}
                </Typography>
                <IconButton onClick={onCancel}>
                    <CloseIcon/>
                </IconButton>
            </div>

            <SimpleForm
                variant="outlined"
                className={classes.form}
                basePath={controllerProps.basePath}
                record={controllerProps.record}
                save={controllerProps.save}
                version={controllerProps.version}
                resource={resource}
                toolbar={<EditToolBar/>}
            >
                {editFormFields.map((Line, index) => (Line))}
            </SimpleForm>
        </div>
    )
}

const CustomEdit = connect(undefined, {refreshView})(EditController)
export {CustomEdit}
