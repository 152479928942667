import Button from '@material-ui/core/Button'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'
import React, {useState} from 'react'
import {useRefresh, useSetLocale, useTranslate} from "react-admin"
import i18nProvider, {languages} from "../../providers/i18nProvider"
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
  langBtn: {
    height: 40,
    width: 64,
  },
  img: {
    margin: `0 4px`,
    minWidth: 18,
  },
  text: {
    margin: `0 4px`,
    textTransform: 'uppercase',
    fontSize: 14,
    fontWeight: 600,
  },
  item: {
    minWidth: 40,
  },
  itemImg: {
    minWidth: 18,
  }
})


function LanguageSwitcher() {
  const refresh = useRefresh()
  const classes = useStyles()
  const [menu, setMenu] = useState(null)
  const translate = useTranslate()
  const setLocale = useSetLocale()
  const currentLocale = i18nProvider.getLocale()
  const currentLanguage = languages.find(l => l.locale === currentLocale)

  const _setLocale = async (locale) => {
    localStorage.setItem('locale', locale)
    await setLocale(locale)
    refresh()
  }

  const langMenuClick = (event) => {
    setMenu(event.currentTarget)
  }

  const langMenuClose = () => {
    setMenu(null)
  }

  function handleLanguageChange(lng) {
    _setLocale(lng.locale).then(() => {

    })
    langMenuClose()
  }

  return (
    <>
      <Button
        className={classes.langBtn}
        onClick={langMenuClick}
        color="inherit"
      >
        <img
          className={classes.img}
          src={`/assets/images/flags/${currentLanguage.flag}.png`}
          alt={currentLanguage.name}
        />
        <Typography className={classes.text}>
          {currentLanguage.locale}
        </Typography>
      </Button>

      <Popover
        open={Boolean(menu)}
        anchorEl={menu}
        onClose={langMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: 'py-8',
        }}
      >
        {languages.map((lng) => {
          const title = translate(lng.name)
          return (
            <MenuItem key={lng.locale} onClick={() => handleLanguageChange(lng)}>
              <ListItemIcon className={classes.item}>
                <img
                  className={classes.itemImg}
                  src={`/assets/images/flags/${lng.flag}.png`}
                  alt={title}
                />
              </ListItemIcon>
              <ListItemText primary={title}/>
            </MenuItem>
          )
        })}
      </Popover>
    </>
  )
}

export default LanguageSwitcher
