import React, { useEffect, useState } from "react"
import {
  Edit,
  SimpleForm,
  useTranslate,
  TextInput,
  AutocompleteArrayInput,
  useQuery,
  useEditController,
} from "react-admin"
import {makeStyles} from "@material-ui/core/styles"
import { Box } from "@material-ui/core";
import { useDataProvider } from "ra-core";

const useStyles = makeStyles({
  plane: {
    width: '100%',
    maxWidth: 840,
  },
})

const UserEdit = (props) => {
  const dataProvider = useDataProvider()
  const {basePath, resource, id} = props
  const translate = useTranslate()
  const classes = useStyles()

  const [merchants, setMerchants] = useState([])
  const [allMerchants, setAllMerchants] = useState([])

  const merchantsLinkReq = useQuery({
    type: 'getList',
    resource: "sbank/usermerchant",
    payload: {
      filter: {},
      pagination: {page: 1, perPage: 10e3},
    },
  })

  const merchantsReq = useQuery({
    type: 'getList',
    resource: "fbp_pricing/merchant",
    payload: {
      filter: {},
      pagination: {page: 1, perPage: 10e3},
    },
  })

  useEffect(() => {
    if(!merchantsLinkReq.loaded || !merchantsReq.loaded) {
      return
    }
    setAllMerchants(merchantsReq.data.map(i => {
      return {id: i.id, name: i.iname}
    }))
    setMerchants(merchantsLinkReq.data.filter(i => i.user.toString() === id.toString()).map(i => {
      return i.merchant
    }))
  }, [merchantsLinkReq, merchantsReq])

  const controllerProps = useEditController({
    ...props,
    basePath,
    resource,
    id,
    undoable: false,
  })

  const save = controllerProps.save
  controllerProps.save = (data, redirectTo, _a = {}) => {
    const newData = {...data}
    delete newData.merchants

    const toSave = data.merchants.filter(id => !merchants.includes(id))
    const toRemove = merchants.filter(id => !data.merchants.includes(id))

    const requests = []

    toSave.forEach(merchantID => {
      requests.push(dataProvider.create('sbank/usermerchant', {
        data: {
          user: id,
          merchant: merchantID,
        },
      }))
    })

    toRemove.forEach(merchantID => {
      const link = merchantsLinkReq.data.find(i => i.merchant.toString() === merchantID.toString())
      requests.push(dataProvider.delete('sbank/usermerchant', {
        id: link.id,
      }))
    })

    Promise.all(requests).then(() => {
      save(data, redirectTo)
    })
  }

  return (
    <>
      <Edit
        title={`${translate('resources.fbp_pricing/terminalowner.edit')}`}
        {...props}
      >
        <SimpleForm variant="outlined"
          initialValues={{merchants: merchants}}
          className={classes.simpleForm}
          basePath={controllerProps.basePath}
          record={controllerProps.record}
          save={controllerProps.save}
          version={controllerProps.version}
        >
          <Box fullWidth className={classes.plane}>
          <TextInput variant="outlined" label={`${translate('resources.sbank/sbankuser.fields.username')}`} source="username"/>

          <AutocompleteArrayInput
            variant="outlined"
            fullWidth
            label={`${translate('resources.sbank/sbankuser.fields.merchants')}`}
            source="merchants"
            choices={allMerchants}
          />

          {/*<ReferenceArrayInput source="groups" reference="auth/group">*/}
          {/*  <ArrayInput optionText="iname" />*/}
          {/*</ReferenceArrayInput>*/}

          {/*<TextInput variant="outlined" source="password"/>*/}

          {/*<EditReferenceFields*/}
          {/*  label={translate(`resources.fbp_pricing/merchantcommission.name`, {smart_count: 1})}*/}
          {/*  editLabel={translate(`resources.fbp_pricing/merchantcommission.edit`)}*/}
          {/*  createLabel={translate(`resources.fbp_pricing/merchantcommission.create`)}*/}
          {/*  baseProps={props}*/}
          {/*  resourceName={"commission"}*/}
          {/*  resource="fbp_pricing/merchantcommission"*/}
          {/*  tableConfig={{*/}
          {/*    reference: "fbp_pricing/merchantcommission",*/}
          {/*    target: "merchant",*/}
          {/*    filter: {merchant: id},*/}
          {/*  }}*/}
          {/*  tableFields={commission.tableFields}*/}
          {/*  createFormFields={commission.createFormFields}*/}
          {/*  editFormFields={commission.editFormFields}*/}
          {/*/>*/}
          </Box>
        </SimpleForm>
      </Edit>
    </>
  )
}

export default UserEdit
