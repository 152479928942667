import {getResourcePermissions} from "./useResourcePermissions";
import i18nProvider from "../providers/i18nProvider";

const {translate} = i18nProvider

export function addPermissionLabel(resource, source) {
  const {loading, actions} = getResourcePermissions(resource, source)
  if(loading) {
    return false
  }
  if(actions.includes('view')) {
    return translate(`resources.${resource}.fields.${source}`)
  }
  return false
}
