import React from "react"
import {
  Create,
  SimpleForm,
  SelectInput,
  ReferenceInput,
  FileInput,
  FileField,
  BooleanInput,
  useTranslate,
  useEditController,
  useCreateController,
  useNotify
} from "react-admin"
import { JsonEditor } from "../fields/JsonEditor/JsonEditor"
import { validation } from "../../utils/validators"
import { PermissionField } from "../PermissionField/PermissionField"
import { addPermissionLabel } from "../../utils/addPermissionLabel"
import { rawDataProvider } from '../../providers/dataProvider'
import { apiURL } from '../../utils/apiURL'
import { useHistory } from 'react-router-dom'

const validateFields = {
  merchant_id: validation.composeValidators(validation.required),
  endpoint_id: validation.composeValidators(validation.required),
  registry: validation.composeValidators(validation.required)
}

function validate(values) {
  const errors = {}
  Object.entries(validateFields).forEach(([field, v]) => errors[field] = v(values[field]))
  return errors
}


const convertFileToBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file.rawFile);
  });


const BulkWithdrawalCreate = (props) => {
  const translate = useTranslate()
  const {resource, basePath} = props
  const history = useHistory();
  const notify = useNotify();

  const controllerProps = useCreateController({
    ...props,
    basePath,
    resource,
    undoable: false,
  })

  controllerProps.save = (data, redirectTo, _a = {}) => {
    convertFileToBase64(data.registry).then(async (base64File) => {
      data['registry'] = base64File
      const res = rawDataProvider("CUSTOM_POST", `${apiURL}/create_bulk_withdrawal`, {
        ...data
      })
      res.then(async (data) => {
        if (data.json.status === 'success') {
          history.push(`/fbp_pricing/bulkwithdrawal/${data.json.id}`)
        }
      }).catch(async error => {
        if (error?.message) {
          notify(error?.message, 'error', null, false, 4000)
        }
      })
    })
  }

  return (
    <>
      <Create
        title={`${translate('resources.fbp_pricing/bulkwithdrawal.create')}`}
        {...props}
      >
        <SimpleForm
          variant="outlined"
          initialValues={{data: {}}}
          basePath={controllerProps.basePath}
          record={controllerProps.record}
          save={controllerProps.save}
          version={controllerProps.version}
          validate={validate}
        >
          <ReferenceInput source="merchant_id" reference="fbp_pricing/merchant">
            <SelectInput optionText="iname"/>
          </ReferenceInput>

          <ReferenceInput source="endpoint_id" reference="fbp_pricing/product">
            <SelectInput optionText="name"/>
          </ReferenceInput>

          <FileInput source="registry" title="input registry">
            <FileField source="registry" title="input registry"/>
          </FileInput>
        </SimpleForm>
      </Create>
    </>
  )
}

export default BulkWithdrawalCreate
