import {Drawer, SpeedDial, SpeedDialAction, SpeedDialIcon, styled} from "@mui/material"
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill'
import {useEffect, useState} from "react"
import Settings from "./Settings"

const actions = [
  {
    icon: <FormatColorFillIcon/>,
    name: "Edit Theme",
  },
]

const StyledSpeedDial = styled(SpeedDial)(({theme}) => ({
  position: "absolute",
  "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
    top: theme.spacing(2),
    left: theme.spacing(2)
  }
}))

export const SettingsSpeedDial = props => {
  const [hidden, setHidden] = useState(true)
  const [drawerOpen, setDrawerOpen] = useState(true)

  useEffect(() => {
    try {
      const value = JSON.parse(localStorage.getItem('themes_debug'))
      if (value === true) {
        setHidden(false)
      }
    } catch (e) {
      //
    }
  }, [])

  if (hidden) {
    return null
  }

  return (
    <>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(!drawerOpen)}
      >
        <Settings />
      </Drawer>

      <StyledSpeedDial
        ariaLabel="Theme debug"
        hidden={hidden}
        direction="left"
        icon={<SpeedDialIcon/>}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={() => setDrawerOpen(true)}
          />
        ))}
      </StyledSpeedDial>
    </>
  )
}
