import React from "react"
import PointOfSaleIcon from "@mui/icons-material/PointOfSale"
import TerminalList from "./TerminalList"
import TerminalEdit from "./TerminalEdit"
import TerminalCreate from "./TerminalCreate"

export const terminal = {
  name: 'fbp_pricing/terminal',
  icon: PointOfSaleIcon,
  edit: TerminalEdit,
  create: TerminalCreate,
  list: TerminalList,
}
