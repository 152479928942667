import React, {useEffect, useState} from "react";
import {Chip} from '@mui/material';
import TranslatedField from "../TranslatedReferenceField/TranslatedField";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography"
import {useRecordContext} from "react-admin";

const useStyles = makeStyles(theme => ({
  chip: {
      '&.MuiChip-root': {
          // backgroundColor: props => {console.log(props.color); return theme.palette[props.color]?.main ? theme.palette[props.color].main : 'rgba(0, 0, 0, 0.08)'},
      },
      '&.MuiChip-outlined': {
          border: props => {return theme.palette[props.color]?.border ? `1px solid ${theme.palette[props.color].border}` : '1px solid rgba(0, 0, 0, 0.08)'},
          color: props => {return theme.palette[props.color]?.text ? `${theme.palette[props.color].text}` : 'rgba(0, 0, 0, 0.08)'},
      }
  },
}))

export const StatusField = ({source, translationKey, colorMap = {}, className, ...props}) => {
    const [value, setValue] = useState("")
    const [color, setColor] = useState("default")

    const classes = useStyles({color, ...props})

    const record = useRecordContext(props);

    useEffect(() => {
        setValue(record ? record[source] : '');
        setColor(colorMap[value] ? colorMap[value] : 'default')
    }, [record, source, value, colorMap])

    if (!record) {
        return null;
    }

    return (
        <Chip
            variant={'outlined'}
            className={classes.chip}
            // color={'secondary'}
            size="small"
            label={<TranslatedField translationKey={translationKey} field={source} record={record}/>}
        />);
}

export default StatusField;
