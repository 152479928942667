import axios from "axios"
import {additionsHeaders} from "../utils/headers";
import {apiURL, reactAdminApiURL} from "../utils/apiURL"
import { cubejsApi } from "./cubejsApi";

const authProvider = {
  login: async ({username, password}) => {
    const loginResponse = await axios({
      method: "POST",
      url: `${apiURL}/auth/token/login/`,
      headers: {
        ...additionsHeaders,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: JSON.stringify({username, password}),
    })
    if (loginResponse.status < 200 || loginResponse.status >= 300) {
      throw new Error(loginResponse.statusText)
    }
    const auth_token = loginResponse.data.auth_token

    cubejsApi.transport.authorization = `Token ${auth_token}`

    const userRes = await axios({
      method: "GET",
      url: `${reactAdminApiURL}/auth/user/`,
      headers: {
        ...additionsHeaders,
        Accept: "application/json",
        'Authorization': `Token ${auth_token}`,
        "Content-Type": "application/json",
      },
    })
    const user = userRes.data.length ? userRes.data[0] : null

    const groupResponse = await axios({
      method: "GET",
      url: `${reactAdminApiURL}/auth/group/`,
      headers: {
        ...additionsHeaders,
        Accept: "application/json",
        'Authorization': `Token ${auth_token}`,
        "Content-Type": "application/json",
      },
    })
    const groups = groupResponse.data
    const roles = user.groups.map(id => {
      const group = groups.find(group => user.groups.includes(id))
      return group?.name
    })

    if (roles.length === 0) {
      roles.push('admin')
    }

    localStorage.setItem("auth_token", auth_token)
    localStorage.setItem("user", JSON.stringify(user))
    localStorage.setItem("user_roles", JSON.stringify(roles))

    return Promise.resolve()
  },
  logout: async () => {
    const auth = localStorage.getItem("auth_token")
    localStorage.removeItem("auth_token")
    localStorage.removeItem("user")
    localStorage.removeItem("permissions")
    localStorage.removeItem("locale")
    localStorage.removeItem("user_roles")
    localStorage.removeItem("chartSettings")

    if (auth) {
      try {
        const response = await axios({
          method: "POST",
          url: `${apiURL}/auth/token/logout`,
          headers: {
            ...additionsHeaders,
            Authorization: `Token ${auth}`,
          },
        })
      } catch (err) {
        const {response} = err
        if (response.status === 400) {
          // return Promise.resolve("/merchant/login")
        }
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText)
        }
      }
    }
    window.location.href = '/merchant/login'
    // return Promise.resolve("/merchant/login")
  },
  checkError: ({status, body}) => {
    if (status === 400 && body.message === 'Invalid token.') {
      localStorage.removeItem("auth_token")
      return Promise.reject()
    }
    // if (status === 401 || status === 403) {
    //   localStorage.removeItem("auth_token")
    //   return Promise.reject()
    // }
    return Promise.resolve()
  },
  checkAuth: () => {
    return localStorage.getItem("auth_token") ? Promise.resolve() : Promise.reject()
  },
  getIdentity: () => {
    const user = JSON.parse(localStorage.getItem('user'))
    const user_roles = JSON.parse(localStorage.getItem('user_roles'))

    if (user_roles.includes('admin')) {
        document.title = 'Admin panel';
    }

    user.role = user_roles.length > 0 ? user_roles[0] : "undefined"
    return Promise.resolve(user)
  },
  getPermissions: () => {
    const roles = JSON.parse(localStorage.getItem('user_roles'))
    return Promise.resolve(roles)
  },
}

export default authProvider
