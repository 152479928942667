import {Filter, SelectInput, Title} from 'react-admin'
import InsertChartIcon from '@mui/icons-material/InsertChart'
import {Card, CardHeader, CardContent} from "@material-ui/core"
import React, {useEffect, useRef, useState} from "react"
import {makeStyles} from "@material-ui/core/styles"
import {Chart} from "../components/chart/Chart"
import {dateFormat, dateParse} from "../utils/dateHelpers"
import {DateRangeInput} from "../components/inputs/DateRangeInput/DateRangeInput"
import {useTranslate} from 'ra-core'
import i18nProvider from '../providers/i18nProvider'
import {CustomInput} from '../components/inputs/CustomInput/CustomInput'

const t = i18nProvider.translate

const useStyles = makeStyles(theme => ({
  root: {
    height: 520,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    marginBottom: 8,
  },
}))

const charts = {
  // По сумме успешных операций (платежи/invoice)
  invoice_sum_price: {
    config: {
      measures: ['FbpPricingInvoice.sumPrice'],
      timeDimensions: "FbpPricingInvoice.createdAt",
      dimensions: ["FbpPricingPaymentstatus.iname"],
    }
  },
  // По количеству успешных операций (платежи/invoice)
  invoice_count: {
    config: {
      measures: ['FbpPricingInvoice.count'],
      timeDimensions: "FbpPricingInvoice.createdAt",
      dimensions: ["FbpPricingPaymentstatus.iname"],
    }
  },
  // По сумме успешных выводов (выплаты/withdrawal)
  withdrawal_sum_amount: {
    config: {
      measures: ['FbpPricingWithdrawalrequest.sumAmount'],
      timeDimensions: "FbpPricingWithdrawalrequest.createdAt",
      dimensions: ["FbpPricingWithdrawalrequest.status"],
      // dimensions: ["FbpPricingProduct.name"],
    }
  },
  // По количеству успешных выводов (выплаты/withdrawal)
  withdrawal_count: {
    config: {
      measures: ['FbpPricingWithdrawalrequest.count'],
      timeDimensions: "FbpPricingWithdrawalrequest.createdAt",
      dimensions: ["FbpPricingWithdrawalrequest.status"],
      // dimensions: ["FbpPricingProduct.name"],
    }
  },
  // // Количество успешных операций по определённому каналу платежа (карты, СБП и т.д.) Тут надо понять, сможем на фронте разделять канал или нужна доработка.
  // По количеству успешных выводов (выплаты/withdrawal)
  // // Сумма успешных операций по определённому каналу платежа (карты, СБП и т.д.)
  // {id: 'FbpPricingWithdrawalrequest.count', name: 'withdrawal_count'},
}

const chartList = Object.entries(charts).map(([key, value]) => ({
  key,
  id: key,
  get name() {
    return t(`pages.charts.charts.${this.key}`)
  },
  config: value.config
}))

const dimensionsList = [
  {
    key: 'merchant',
    id: 'merchant',
    get name() {
      return t(`pages.charts.slice.merchant`)
    },
  },
  {
    key: 'product',
    id: 'product',
    get name() {
      return t(`pages.charts.slice.product`)
    },
  },
]

const dimensionsObject = {
  merchant: ['FbpPricingMerchant.iname'],
  product: ['FbpPricingProduct.name'],
}

const Filters = (props) => {
  const translate = useTranslate()
  return (
    <Filter variant="outlined"
      {...props}
    >
      <SelectInput
        source={"measure"}
        label={translate('pages.charts.chart')}
        choices={chartList}
        allowEmpty={false}
        allowNull={false}
        alwaysOn={true}
      />

      <SelectInput
        source={"dimensions"}
        label={translate('pages.charts.dimension')}
        choices={dimensionsList}
        allowEmpty={true}
        allowNull={true}
        alwaysOn={true}
      />

      {/*{filterCreators.createReferenceInput(*/}
      {/*  "fbp_pricing/merchant",*/}
      {/*  translate('filters.search_by_merchants'),*/}
      {/*  "merchant",*/}
      {/*  "iname"*/}
      {/*)}*/}

      <DateRangeInput
        startText={translate('filters.created_date_from')}
        endText={translate('filters.created_date_to')}
        label={translate('filters.search_by_create_date')}
        source={"dateRange"}
        format={dateFormat}
        parse={dateParse}
        alwaysOn={true}
      />

      <CustomInput
        source="chartType"
        alwaysOn={true}
      />

      {/*<SelectArrayInput*/}
      {/*  style={{minWidth: "200px"}}*/}
      {/*  label={"Payment status"}*/}
      {/*  source={"status"}*/}
      {/*  choices={[*/}
      {/*    {id: 'complete', name: 'Complete'},*/}
      {/*    {id: 'failed', name: 'Failed'},*/}
      {/*    {id: 'pending', name: 'Pending'},*/}
      {/*  ]}*/}
      {/*  alwaysOn={true}*/}
      {/*/>*/}

      {/*<SelectInput*/}
      {/*  source={"measure"}*/}
      {/*  label={translate('filters.granularity')}*/}
      {/*  choices={chartList}*/}
      {/*  allowEmpty={false}*/}
      {/*  allowNull={false}*/}
      {/*  alwaysOn={true}*/}
      {/*/>*/}



    </Filter>
  );
}

const saveFiltersToLocalStorage = (state) => {
  localStorage.setItem('chartSettings', JSON.stringify(state));
}

export function ChartsPage(props) {
  const classes = useStyles()
  const firstTime = useRef(true)

  let initParams = {}

  if(firstTime.current) {
    const search = props.location.search
    const params = new URLSearchParams(search)
    initParams = {
      initMeasure : params.get('measure') ?? "invoice_sum_price",
      initDimensions : params.get('dimensions') ?? null,
      initTimeFilter : params.get('time_filter'),
      initCharType : params.get('char_type') ?? 'bar',
    }

    initParams.initTimeFilter = {
      dateRange__gte: (new Date(new Date().setDate(new Date().getDate() - 30))).toISOString().split('T')[0],
      dateRange__lte: (new Date()).toISOString().split('T')[0],
    }

    firstTime.current = false
  }

  const [initChartSettings, ] = useState(JSON.parse(localStorage.getItem('chartSettings')))
  const [filters, setFilters] = useState([])
  const [timeFilter, setTimeFilter] = useState(initChartSettings?.timeFilter ? initChartSettings.timeFilter : {
    dateRange__gte: (new Date(new Date().setDate(new Date().getDate() - 30))).toISOString().split('T')[0],
    dateRange__lte: (new Date()).toISOString().split('T')[0],
  })
  const [measure, setMeasures] = useState(initChartSettings?.measure ? initChartSettings.measure : 'invoice_sum_price')
  const [dimensions, setDimensions] = useState(initChartSettings?.dimensions ? initChartSettings.dimensions : null)
  const [chartType, setChartType] = React.useState(initChartSettings?.chartType ? initChartSettings.chartType : 'bar');

  useEffect(() => {
    saveFiltersToLocalStorage({
      measure,
      dimensions,
      timeFilter,
      chartType
    })
  }, [timeFilter, measure, dimensions, chartType]);

  const config = charts[measure].config

  return (
    <div>
      <Title title={ChartsPage.menuConfig.title}/>

      {/*<Card className={classes.card}>*/}
      {/*  <CardContent className={classes.root}>*/}
      {/*    <ChartRenderer/>*/}
      {/*  </CardContent>*/}
      {/*</Card>*/}

      <Filters variant="outlined"
        filterValues={{
          dimensions,
          measure,
          chartType,
          dateRange__gte: timeFilter.dateRange__gte,
          dateRange__lte: timeFilter.dateRange__lte,
        }}
        setFilters={values => {
          const newFilters = []

          if(values.chartType) {
            setChartType(values.chartType)
          }

          setMeasures(values.measure)
          setDimensions(values.dimensions)

          if (values.merchant) {
            newFilters.push({
              "member": "FbpPricingMerchant.id",
              "operator": "equals",
              "values": [values.merchant.toString()],
            })
          }

          // if (values.status && values.status.length) {
          //   newFilters.push({
          //     "member": "FbpPricingPaymentstatus.iname",
          //     "operator": "equals",
          //     "values": values.status
          //   })
          // }

          if (values.dateRange__gte && values.dateRange__lte) {
            setTimeFilter({
              dateRange__gte: (new Date(values.dateRange__gte)).toISOString().split('T')[0],
              dateRange__lte: (new Date(values.dateRange__lte)).toISOString().split('T')[0],
            })
          }

          setFilters(newFilters)
        }}
      />

      <Card>
        {/*<CardHeader*/}
        {/*  title="Payments"*/}
        {/*/>*/}
        <CardContent className={classes.root}>
          <Chart
            query={{
              "measures": config.measures,
              "timeDimensions": [
                {
                  "dimension": config.timeDimensions,
                  "granularity": "day",
                  "dateRange": [
                    timeFilter.dateRange__gte,
                    timeFilter.dateRange__lte,
                  ]
                }
              ],
              "order": [],
              "dimensions": dimensionsObject[dimensions] ? dimensionsObject[dimensions] : config.dimensions,
              filters,
            }}
            renderProps={{
              chartType: chartType,
              pivotConfig: {
                "x": [
                  "FbpPricingInvoice.createdAt.month"
                ],
                "y": [
                  "measures"
                ],
                "fillMissingDates": true,
                "joinDateRange": false
              }
            }}
          />
        </CardContent>
      </Card>

    </div>
  )
}


ChartsPage.menuConfig = {
  menu: 'pages.charts.menu',
  title: 'pages.charts.title',
  icon: InsertChartIcon,
  href: '/charts',
}
