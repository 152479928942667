import React, {useEffect, useRef} from "react";
import {Box, TextField as MuiTextField} from "@material-ui/core";
import {DateRangePicker, LocalizationProvider} from "@mui/lab";
import {default as AdapterDateFns} from "@mui/lab/AdapterDateFns";
import {makeStyles} from '@material-ui/core/styles';
import {useInput} from "ra-core";
import {useLocale} from "react-admin";
import locales from "../DatePickers/locales";

const useStyles = makeStyles({
  root: {
    width: 160,
    margin: `16px 0 8px 0`,
  },
});

const normalizeDate = value => {
  if(!(value instanceof Date)) {
    return undefined
  }
  try {
    const offset = value.getTimezoneOffset()
    const date = new Date(value.getTime() - (offset * 60 * 1000))

    return date.toISOString().split('T')[0]
  } catch (e) {
    return undefined
  }
}

const checkValidDate = (value) => {
  let out = null
  try {
    const date = new Date(value)
    if(date instanceof Date && !isNaN(date)) {
      out = date
    }
  } catch (e) {
    return out
  }
  return out
}

export const DateRangeInput = props => {
  const classes = useStyles();
  const currentLoc = useLocale();
  const {alwaysOn} = props
  const [value, setValue] = React.useState([null, null])
  const firstTime = useRef(true)

  const {
    format,
    helperText,
    onBlur,
    onFocus,
    onChange,
    options,
    parse,
    resource,
    source,
    validate,
    locale = locales[currentLoc],
    ...rest
  } = props;

  const {
    id,
    input: inputFrom,
    isRequired,
    meta: {error, submitError, touched},
  } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    source: `${source}__gte`,
    type: 'text',
    validate,
    ...rest,
  });

  const {
    input: inputTo,
  } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    source: `${source}__lte`,
    type: 'text',
    validate,
    ...rest,
  });

  useEffect(() => {
    let dateFrom = normalizeDate(value[0])
    let dateTo = normalizeDate(value[1])

    if(firstTime.current) {
      dateFrom = checkValidDate(inputFrom.value)
      dateTo = checkValidDate(inputTo.value)
      firstTime.current = false
      setValue([dateFrom, dateTo])
      return
    }

    inputFrom.onChange(dateFrom)
    inputTo.onChange(dateTo)
  }, [value])

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale} >
      <DateRangePicker
        alwaysOn={alwaysOn}
        resettable
        inputFormat="dd.MM.yyyy"
        startText={props.startText}
        endText={props.endText}
        value={value}
        onChange={(newValue) => {
          setValue(newValue)
        }}
        renderInput={(startProps, endProps) => (
          <React.Fragment>
            <MuiTextField
              {...inputFrom}
              className={classes.root}
              size="small"
              variant="outlined"
              {...startProps}
            />
            <Box mx={1}/>
            <MuiTextField
              {...inputTo}
              className={classes.root}
              size="small"
              variant="outlined"
              {...endProps}
            />
          </React.Fragment>
        )}
      />
    </LocalizationProvider>
  )
}
