const additionsHeaders = {}

Object.keys(process.env).forEach(envKey => {
  if(envKey.includes('REACT_APP_ADDITION_HEADER')) {
    const header = envKey.replace('REACT_APP_ADDITION_HEADER_', '')
    additionsHeaders[header] = process.env[envKey]
  }
})

export {additionsHeaders}
