import React from "react"
import GroupIcon from '@mui/icons-material/Group';
import UserList from "./UserList";
import UserEdit from "./UserEdit";
import UserCreate from "./UserCreate";

export const user = {
  name: 'sbank/sbankuser',
  icon: GroupIcon,
  list: UserList,
  edit: UserEdit,
  create: UserCreate,
}
