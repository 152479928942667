import React from "react"
import {Create, SimpleForm, TextInput, ReferenceInput, SelectInput, useTranslate} from "react-admin"
import {useResourcePermissions} from "../../../utils/useResourcePermissions"
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
  simpleForm: {
    maxWidth: 375,
  }
})

const TerminalownerCreate = (props) => {
  const translate = useTranslate()
  const {resource} = props
  const classes = useStyles()
  const {loading, actions, fields} = useResourcePermissions(resource)

  return (
    <Create
      title={`${translate('resources.fbp_pricing/terminalowner.create')}`}
      {...props}
    >
      <SimpleForm variant="outlined"  className={classes.simpleForm}>
        <TextInput fullWidth variant="outlined" source="iname"/>
        <TextInput fullWidth variant="outlined" source="limit_card_day_count" />
        <TextInput fullWidth variant="outlined" source="limit_card_withdrawal_day_count" />
      </SimpleForm>
    </Create>
  )
}

export default TerminalownerCreate
