import React from "react"
import {useResourcePermissions} from "../../utils/useResourcePermissions"

export const PermissionField = ({children, ...props}) => {
  const {resource, source} = props
  const {loading, actions} = useResourcePermissions(resource, source)

  if(loading) {
    return null
  }

  if(!actions.includes('view')) {
    return null
  }

  if(!actions.includes('edit')) {
    props.disabled = true
  }

  if (React.isValidElement(children)) {
    return React.cloneElement(children, props)
  }

  return children
}
