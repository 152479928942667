import React, {useEffect} from "react"
import authProvider from "./providers/authProvider"
import dataProvider from "./providers/dataProvider"
import {createBrowserHistory as createHistory} from "history"
import {LocalizationProvider} from "@mui/lab"
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import createStore from "./store/createStore"
import {Provider} from "react-redux"
import {SbankAdmin} from "./Admin"
import {Theming} from "./components/themes/Theming";

const history = createHistory()

const App = () => {
  useEffect(() => {
    const meta = document.createElement('meta')
    meta.name = "version"
    meta.content = process.env.REACT_APP_VERSION
    document.head.appendChild(meta)
  }, [])

  return (
    <Provider
      store={createStore({
        authProvider,
        dataProvider,
        history,
      })}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Theming />
        <SbankAdmin history={history} />
      </LocalizationProvider>
    </Provider>
  )
}

export default App
