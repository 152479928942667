import React from "react";
import lists from "../../components/lists";
import WebhookIcon from "@mui/icons-material/Webhook";
import WebhookCreate from "../../components/create/WebhookCreate";
import WebhookEdit from "../../components/edit/WebhookEdit";

export const webhook = {
    icon: WebhookIcon,
    name: 'fbp_webhook/webhook',
    create: WebhookCreate,
    edit: WebhookEdit,
    list: lists.WebhookList
}