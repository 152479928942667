import React from 'react'
import {Link} from 'react-router-dom'
import Button from '@material-ui/core/Button'
import {Add} from '@material-ui/icons'
import {useTranslate} from "react-admin";

export const CreateButton = ({baseURL, resourceName, label}) => {
  const translate = useTranslate()

  return (
    <Button
      startIcon={<Add/>}
      component={Link}
      color="primary"
      to={`${baseURL}/${resourceName}/create`}
    >
      {label ? label : `${translate('ra.action.create')} ${resourceName[0].toUpperCase() + resourceName.slice(1)}`}
    </Button>
  )
}
