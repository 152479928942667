import React from "react"
import {Edit, SimpleForm, TextInput, NumberInput, useTranslate} from "react-admin"
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
  simpleForm: {
    maxWidth: 375
  }
})

const TerminalownerEdit = (props) => {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <>
      <Edit
        title={`${translate('resources.fbp_pricing/terminalowner.edit')}`}
        {...props}
      >
        <SimpleForm variant="outlined" className={classes.simpleForm} >
          <TextInput fullWidth variant="outlined" source="iname"/>
          <NumberInput fullWidth source="limit_card_day_count" />
          <NumberInput fullWidth source="limit_card_withdrawal_day_count" />
        </SimpleForm>
      </Edit>
    </>
  );
};

export default TerminalownerEdit;
