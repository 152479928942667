import {
  SelectInput,
  SelectArrayInput,
  AutocompleteInput,
  ReferenceInput,
  TextInput,
} from "react-admin"
import i18nProvider from "../providers/i18nProvider"
import DateInput from "../components/inputs/DatePickers/DateInput";
import React from "react";
import CustomSearchInput from "../components/inputs/CustomSearchInput/CustomSearchInput";

const createSearchInput = (source) => {
  const {translate} = i18nProvider
  return (
    <CustomSearchInput
      variant="outlined"
      source={source}
      resettable
      alwaysOn
    />
  )
}

const createTextSearchInput = ({source, label, alwaysOn}) => {
  return (
    <TextInput
      label={label}
      resettable
      source={source}
      alwaysOn={alwaysOn}
    />
  )
}

const createNumberSearchInput = ({source, label, alwaysOn}) => {
  return (
    <TextInput
      label={label}
      resettable
      source={source}
      format={v => {
        const newValue = Number.parseFloat(v)
        if(Number.isNaN(newValue)) {
          return null
        }
        return newValue.toString()
      }}
      alwaysOn={alwaysOn}
    />
  )
}

const createDateInput = ({label, source, format, alwaysOn}) => {
  const {translate} = i18nProvider
  return (
      <DateInput
          variant="outlined"
          options={{format: 'dd/MM/yyyy', ampm: false, clearable: true}}
          label={label ? label : translate('filters.search_by_create_date')}
          source={source}
          alwaysOn={alwaysOn}
      />
  )
}

const createReferenceInput = (reference, label, source, optionText, alwaysOn=true) => {
  return (
    <ReferenceInput
      // style={{ width: "350px" }}
      label={label}
      source={source}
      reference={reference}
      alwaysOn={alwaysOn}
      allowEmpty
    >
      <AutocompleteInput
        optionText={optionText}
      />
    </ReferenceInput>
  )
}

const createArrayInput = (source, label, choices) => {
  return (
    <SelectArrayInput
      style={{ width: "230px" }}
      label={label}
      source={source}
      choices={choices}
    />
  )
}

const createSelectInput = (source, label, choices) => {
  return (
    <SelectInput
      style={{ width: "230px" }}
      label={label}
      source={source}
      choices={choices}
    />
  )
}

const filterCreators = {
  createSearchInput,
  createTextSearchInput,
  createNumberSearchInput,
  createDateInput,
  createReferenceInput,
  createArrayInput,
  createSelectInput,
}

export default filterCreators
