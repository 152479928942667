import lists from "../../components/lists"
import React from "react"
import MerchantsEdit from "../../components/edit/MerchantnsEdit"
import MerchantCreate from "../../components/create/MerchantCreate"
// import PointOfSaleIcon from "@mui/icons-material/PointOfSale"
import {Storefront} from "@mui/icons-material"

export const merchant = {
  name: 'fbp_pricing/merchant',
  icon: Storefront,
  edit: MerchantsEdit,
  create: MerchantCreate,
  list: lists.MerchantsList,
}
