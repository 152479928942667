import React from "react"
import {Edit, SimpleForm, TextInput, ReferenceInput, SelectInput} from "react-admin"

const MerchantUrlEdit = (props) => {
  return (
    <>
      <Edit
        title=" "
        {...props}
      >
        <SimpleForm variant="outlined" >
          <ReferenceInput source="merchant_url_type" reference="fbp_pricing/merchanturltype">
            <SelectInput optionText="iname"/>
          </ReferenceInput>
          <TextInput variant="outlined" source="url"/>
          <ReferenceInput source="merchant" reference="fbp_pricing/merchant">
            <SelectInput optionText="iname"/>
          </ReferenceInput>
        </SimpleForm>
      </Edit>
    </>
  )
}

export default MerchantUrlEdit
