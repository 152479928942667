import React, {useState} from "react";
import {
    Edit,
    SimpleForm,
    useTranslate,
    TextInput,
    NumberInput,
    AutocompleteInput,
    DataProviderContext, SelectInput, useLocale
} from "react-admin";
import {validation} from "../../utils/validators";
import {JsonEditor} from "../fields/JsonEditor/JsonEditor";
import DateTimeInput from "../inputs/DatePickers/DateTimeInput";
import DateFnsUtils from '@date-io/date-fns';
import locales from '../inputs/DatePickers/locales';
import {makeStyles} from "@material-ui/core/styles";

const httpMethods = [
    {
        key: 'GET',
        id: 'GET',
        name: 'GET'
    },
    {
        key: 'POST',
        id: 'POST',
        name: 'POST'
    },
];

const statuses = [
    {
        key: 'preparing',
        id: 'preparing',
        name: 'preparing'
    },
    {
        key: 'pending',
        id: 'pending',
        name: 'pending'
    },
    {
        key: 'success',
        id: 'success',
        name: 'success'
    },
    {
        key: 'process',
        id: 'process',
        name: 'process'
    },
    {
        key: 'fail',
        id: 'fail',
        name: 'fail'
    },
];

const types = [
    {
        key: 'invoice',
        id: 'invoice',
        name: 'invoice'
    },
    {
        key: 'withdrawal',
        id: 'withdrawal',
        name: 'withdrawal'
    },
    {
        key: 'refund',
        id: 'refund',
        name: 'refund'
    },
];

const validateFields = {
    merchant: validation.composeValidators(validation.required),
    product: validation.composeValidators(validation.required),
    url: validation.composeValidators(validation.required),
    method: validation.composeValidators(validation.required),
    retry_count: validation.composeValidators(validation.required, validation.isInteger, validation.minInt(0)),
    created_at: validation.composeValidators(validation.required),
    next_retry_time: validation.composeValidators(validation.required),
    status: validation.composeValidators(validation.required),
    invoice_id: validation.composeValidators(validation.required, validation.isInteger, validation.minInt(0)),
    withdrawal_request_id: validation.composeValidators(validation.required, validation.isInteger, validation.minInt(0)),
    get: validation.composeValidators(validation.required, validation.json),
    post: validation.composeValidators(validation.required, validation.json),
}

function validate(values) {
    const errors = {}
    Object.entries(validateFields).forEach(([field, v]) => errors[field] = v(values[field]))
    return errors
}

const useStyles = makeStyles({
     editor: {
         minWidth: 450,

        '& .MuiInputBase-root': {
            overflow: "hidden",
            resize: "both",
        }
    },
});

const WebhookEdit = (props) => {
    const dataProvider = React.useContext(DataProviderContext);
    const translate = useTranslate();
    const [merchants, setMerchants] = useState([]);
    const [products, setProducts] = useState([]);
    const locale = useLocale();
    const classes = useStyles();

    React.useEffect(async () => {
        dataProvider.getList("fbp_pricing/merchant", {
            filter: {},
            pagination: {
                page: 1,
                perPage: 25
            },
            sort: {
                field: "id",
                order: "DESC"
            }
        })
            .then(({data}) => {
                setMerchants(data);
            })
            .catch(error => {
                console.log(error);
            })

        dataProvider.getList("fbp_pricing/product", {
            filter: {},
            pagination: {
                page: 1,
                perPage: 100
            },
            sort: {
                field: "id",
                order: "DESC"
            }
        })
            .then(({data}) => {
                setProducts(data);
            })
            .catch(error => {
                console.log(error);
            })
    }, []);


    return (
        <Edit
            title={translate('resources.fbp_webhook/webhook.edit')}
            {...props}
        >
            <SimpleForm variant="outlined"
                validate={validate}
            >
                <DateTimeInput variant="outlined" source="created_at" options={{ format: 'dd.MM.yyyy, HH:mm:ss', ampm: false, clearable: true }} />
                <AutocompleteInput
                    source="merchant"
                    choices={merchants}
                    optionText="iname"
                    optionValue="id"
                />
                <AutocompleteInput
                    source="product"
                    choices={products}
                    optionText="name"
                    optionValue="id"
                />
                <TextInput variant="outlined" source="url"/>
                <JsonEditor
                    overrideOptions={{
                        wordWrap: 'on',
                    }}
                    className={classes.editor}
                    style={{marginBottom: '40px', marginTop: '10px'}}
                    validate={validation.composeValidators(validation.required, validation.json)}
                    label="GET"
                    source="get"
                />
                <JsonEditor
                    overrideOptions={{
                        wordWrap: 'on',
                    }}
                    className={classes.editor}
                    style={{marginBottom: '30px'}}
                    validate={validation.composeValidators(validation.required, validation.json)}
                    label="POST"
                    source="post"
                />
                <SelectInput
                    source="method"
                    choices={httpMethods}
                />
                <NumberInput source="retry_count" min={0} step={1}/>
                <DateTimeInput variant="outlined" source="next_retry_time" options={{ format: 'dd.MM.yyyy, HH:mm:ss', ampm: false, clearable: true }}/>
                <SelectInput
                    source="status"
                    choices={statuses}
                />
                <SelectInput
                    source="webhook_type"
                    choices={types}
                />
                <NumberInput source="response_http_status" min={1} step={1}/>
                <TextInput variant="outlined" multiline source="response_body"/>
                <TextInput variant="outlined" source="merchant_order"/>
                <NumberInput source="invoice_id" min={1} step={1}/>
                <NumberInput source="withdrawal_request_id" min={1} step={1}/>
            </SimpleForm>
        </Edit>
    )
}

export default WebhookEdit;