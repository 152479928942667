import React from 'react'
import TextField from '@material-ui/core/TextField'
import {useInput} from 'ra-core'
import MonacoEditor from 'react-monaco-editor'
import {useRecordContext} from "react-admin";
import {makeStyles} from "@material-ui/core/styles";

export const Editor = props => {
    let value = typeof props.value === 'string' ? props.value : JSON.stringify(props.value)
    if (props.disabled) {
        try {
            value = JSON.stringify(JSON.parse(value), 2, 2)
        } catch (e) {
            //
        }
    }

    const onChange = (value, e) => {
        try {
            props.onChange(value, e);
        } catch (e) {
            //
        }
    }

    const options = {
        lineNumbers: 'off',
        glyphMargin: false,
        folding: false,
        lineDecorationsWidth: 0,
        lineNumbersMinChars: 0,
        automaticLayout: true,
        minimap: {enabled: false},
        readOnly: props.disabled,
        validate: true,
        ...props.overrideOptions
    };

    return (
        <MonacoEditor
            width="100%"
            language="json"
            value={value}
            // options={{
            //     lineNumbers: 'off',
            //     glyphMargin: false,
            //     folding: false,
            //     lineDecorationsWidth: 0,
            //     lineNumbersMinChars: 0,
            //     automaticLayout: true,
            //     minimap: {enabled: false},
            //     wordWrap: 'on',
            //     readOnly: props.disabled,
            //     validate: true,
            // }}
            options={options}
            onChange={onChange}
        />
    )
}

Editor.defaultProps = {
    overrideOptions: {}
}

const useEditorStyles = makeStyles({
     baseEditor: {
        '& .MuiInputBase-root': {
            '& .react-monaco-editor-container': {
                minHeight: 162
            }
        }
    },
});

export const JsonEditor = ({label, source, initialSourceValue, validate, className, classes = {}, style, ...props}) => {
    const fieldProps = {}
    const record = useRecordContext(props)
    const baseClasses = useEditorStyles();

    let initialValue = record ? record[source] : undefined
    if (initialSourceValue) {
        initialValue = record ? record[initialSourceValue] : undefined
    }

    const input = useInput({
        name: source,
        validate,
        initialValue: typeof initialValue === "object" ? JSON.stringify(initialValue) : initialValue,
        parse: (value) => {
            try {
                return JSON.parse(value);
            } catch (e) {
                return value
            }
        }
    })
    let {
        input: {value, onChange},
        meta: {touched, error, dirty},
        isRequired
    } = input

    return (
        <TextField
            classes={classes}
            className={`${baseClasses.baseEditor} ${className}`}
            style={style}
            {...fieldProps}
            fullWidth
            multiline
            InputLabelProps={{shrink: true}}
            variant="outlined"
            label={label}
            InputProps={{inputComponent: Editor}}
            inputProps={{...props, source, value, onChange}}
            error={touched && !!error}
            helperText={touched && error}
        />
    )
}
