import {
  DateField, FunctionField,
  ReferenceField,
    ReferenceInput,
  SimpleForm,
  TextField,
  TextInput,
  useEditController, useTranslate,
} from "react-admin";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {JsonEditor} from "../fields/JsonEditor/JsonEditor";
import {Box} from "@material-ui/core";
import {CurrencyField} from "../fields/CurrencyField/CurrencyField";
import {Stack} from "@mui/material";
import {PermissionField} from "../PermissionField/PermissionField";
import {addPermissionLabel} from "../../utils/addPermissionLabel";
import StatusField from "../fields/StatusField/StatusField";
import {colorMapping} from "../../configs/withdrawalStatuses";
import DateTimeInput from "../inputs/DatePickers/DateTimeInput";

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '100vw',
    width: 500,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '12px 24px 0 24px',
  },
  form: {
    padding: '0 8px'
  },
  inlineField: {
    background: 'red',
    display: 'inline-block',
    width: '50%',
  },
  dateTime: {
    width: '100%'
  },
   box: {
    position: 'relative',
    border: '1px solid rgba(0, 0, 0, .23)',
    borderRadius: '4px',
    padding: '12.5px 14px'
  },
  label: {
    position: 'absolute',
    top: -3,
    left: 0,
    color: 'rgba(0, 0, 0, 0.38)',
    fontSize: '1rem',
    // fontWeight: 500,
    marginBottom: '6px',
    transform: 'translate(14px, -6px) scale(0.75)',
    transformOrigin: 'top left',
    backgroundColor: '#fff',
    zIndex: 100,
    padding: '0 3px'
  }
}))

export function WithdrawalView(props) {
  const {basePath, resource, id, onCancel} = props
  const classes = useStyles()
  const translate = useTranslate()

  const controllerProps = useEditController({
    basePath: `${basePath}`,
    resource,
    id,
    undoable: false,
  })

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h6">
          {translate(`resources.fbp_pricing/withdrawalrequest.name`, {smart_count: 1})} #{id}
        </Typography>
        <IconButton onClick={onCancel}>
          <CloseIcon/>
        </IconButton>
      </div>

      <SimpleForm
          variant={'outlined'}
        className={classes.form}
        basePath={controllerProps.basePath}
        record={controllerProps.record}
        save={controllerProps.save}
        version={controllerProps.version}
        resource={resource}
        toolbar={null}
      >
        <ReferenceInput disabled fullWidth source="merchant" reference="fbp_pricing/merchant" link={true} sortable={false}>
          <TextInput source="iname"/>
        </ReferenceInput>

        <ReferenceInput disabled fullWidth source="primary_product" reference="fbp_pricing/product" link={true} sortable={false}>
          <TextInput source="name"/>
        </ReferenceInput>

        <TextInput disabled fullWidth source="merchant_order"/>

        <Stack
          style={{width: 'auto'}}
          direction="row"
          justifyContent="space-between"
          alignItems="stretch"
          spacing={1}
          sx={{marginBottom: 1}}
        >
          <Box sx={{flexGrow: 1, flexBasis: 100}}>
            <CurrencyField resource={resource} source="requested_amount" codeSource='currency_code'/>
          </Box>
          <Box sx={{flexGrow: 1, flexBasis: 100}}>
            <CurrencyField resource={resource} source="amount" codeSource='currency_code'/>
          </Box>
          <Box sx={{flexGrow: 1, flexBasis: 100}}>
            <CurrencyField resource={resource} source="fee_from_merchant" codeSource='currency_code'/>
          </Box>
        </Stack>

        <PermissionField
          source="primary_payment_gate"
          // addLabel={addPermissionLabel(resource, "primary_payment_gate")}
          disabled
          fullWidth
        >
          <ReferenceInput
            source="primary_payment_gate"
            reference="fbp_pricing/paymentgate"
            link={false}
            sortable={false}
          >
            <TextInput source="iname" sortable={false}/>
          </ReferenceInput>
        </PermissionField>

        <PermissionField
          source="external_id"
            disabled
            fullWidth
        >
          <TextInput source="external_id"/>
        </PermissionField>

        <Stack
            style={{width: 'auto'}}
            direction="column"
            justifyContent="space-between"
            alignItems="stretch"
            spacing={1}
            sx={{marginBottom: 1}}
          >
            <Box className={classes.box} sx={{flexGrow: 1}}>
              <div className={classes.label}>{ translate(`resources.fbp_pricing/withdrawalrequest.fields.status`) }</div>
                <StatusField colorMap={colorMapping}  translationKey={`resources.fbp_pricing/withdrawalrequest.statuses`} source="status"/>
            </Box>
          </Stack>

        <DateTimeInput className={classes.dateTime} disabled variant="outlined" source="created_at" options={{ format: 'dd.MM.yyyy, HH:mm:ss', ampm: false, clearable: true, disabled: true, width: '100%' }} />

        <DateTimeInput className={classes.dateTime} disabled variant="outlined" source="finished_at" options={{ format: 'dd.MM.yyyy, HH:mm:ss', ampm: false, clearable: true, disabled: true, width: '100%' }} />

        <Stack
            style={{width: 'auto'}}
            direction="column"
            justifyContent="space-between"
            alignItems="stretch"
            spacing={1}
            sx={{marginBottom: 1, marginTop: 2.5}}
          >
            <Box className={classes.box} sx={{flexGrow: 1}}>
              <div className={classes.label}>{ translate(`resources.fbp_pricing/withdrawalrequest.fields.is_test`) }</div>
             <FunctionField source="is_test" render={record => translate(`common.${record.is_test ? 'yes' : 'no'}`)} />
            </Box>
          </Stack>

        <PermissionField
          source="data"
          addLabel={addPermissionLabel(resource, "data")}
          fullWidth
        >
          <JsonEditor disabled={true}/>
        </PermissionField>

        <PermissionField
          source="logs"
          addLabel={addPermissionLabel(resource, "logs")}
          fullWidth
        >
          <TextInput variant="outlined" style={{width: '100%'}} multiline maxRows={8} disabled={true}/>
        </PermissionField>
      </SimpleForm>
    </div>
  )
}
