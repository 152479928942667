import lists from "../../components/lists"
import React from "react"
import ProductCreate from "../../components/create/ProductCreate"
import ProductEdit from "../../components/edit/ProductEdit"
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits"

export const product = {
  name: 'fbp_pricing/product',
  icon: ProductionQuantityLimitsIcon,
  edit: ProductEdit,
  // create: ProductCreate,
  list: lists.ProductsList,
}
