import React from "react"
import {SimpleForm, TextInput, Toolbar, Button, DataProviderContext, Create, useLogout, useTranslate} from "react-admin"
import SaveIcon from '@mui/icons-material/Save'
import {rawDataProvider} from "../../providers/dataProvider";
import {apiURL} from "../../utils/apiURL";

const ChangePasswordEdit = (props) => {
  const logout = useLogout()
  const [password, setPassword] = React.useState("")
  const [password2, setPassword2] = React.useState("")
  const [disabledButton, setDisabledButton] = React.useState(true)
  const translate = useTranslate()

  const changePassword = (e) => {
    setPassword(e.target.value)
  }
  const changePassword2 = (e) => {
    setPassword2(e.target.value)
  }

  React.useEffect(() => {
    if (password && password2 && password === password2) {
      setDisabledButton(false)
    } else {
      setDisabledButton(true)
      new Error("test")
    }
  }, [password, password2])

  const changePasswordQuery = () => {
    const request = rawDataProvider("CUSTOM_POST", `${apiURL}/restore-password`, {
      user_token: localStorage.getItem("auth_token"),
      password: password,
    })
    request.then(() => logout())
  }

  const EditToolbar = (props) => (
    <Toolbar {...props}>
      <Button
        onClick={() => changePasswordQuery()}
        disabled={disabledButton}
        label={translate('forms.change_password.submit')}
      >
        <SaveIcon/>
      </Button>
    </Toolbar>
  )

  return (
    <>
      <Create
        resource="auth/user"
        redirect={false}
        basePath="/change-password"
        title="Change password"
        {...props}
      >
        <SimpleForm variant="outlined"  toolbar={<EditToolbar/>}>
          <TextInput
            label={translate('forms.change_password.password')}
            source="password"
            type="password"
            onChange={changePassword}
          />
          <TextInput
            label={translate('forms.change_password.confirm_password')}
            source="password2"
            type="password"
            onChange={changePassword2}
          />
        </SimpleForm>
      </Create>
    </>
  )
}

export default ChangePasswordEdit
