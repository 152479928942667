import i18nProvider from "../providers/i18nProvider";

const {translate} = i18nProvider

export const terminalStatuses = {
  active: {
    id: 'active',
  },
  deleted: {
    id: 'deleted',
  },
  disabled: {
    id: 'disabled'
  },
}

export const terminalStatusesChoices = [
  ...(Object.keys(terminalStatuses).map(status => ({
    id: terminalStatuses[status].id,
    get name() {
        return translate(`resources.fbp_pricing/terminal.statuses.` + this.id);
    },
  })))
]
