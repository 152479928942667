import i18nProvider from "../providers/i18nProvider";

const {translate} = i18nProvider

export const commission_objects = ['refund', 'invoice', 'withdrawal'];

export const commission_objects_choices = commission_objects.map(type => ({
    iname: type,
    get name() {
        return translate(`resources.fbp_pricing/merchantcommission.commission_types.` + this.iname);
    },
}))

