import React from "react";
import {makeStyles} from '@material-ui/core/styles';
import {Datagrid, EditButton, ReferenceManyField, DeleteButton, List, useListContext} from "react-admin";
import {CreateButton} from "./create/CreateButton";
import {EditRoute} from "./edit/EditRoute";
import {CreateRoute} from "./create/CreateRoute";
import {useResourcePermissions} from "../../utils/useResourcePermissions";

const useStyles = makeStyles({
    title: {
        fontSize: 24,
        marginBottom: 12,
    },
    controlButton: {
        // width: '146px',
    },
    table: {
        marginBottom: 6
    },
    content: {
          boxShadow: 'none',
    },
    datagridTable: {
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)'
    }
})

export const ListContent = (props) => {
    const {
        combinedClasses,
        label,
        rowClick,
        tableFields,
        canEdit,
        allowEdit,
        canDelete,
        allowDelete,
        baseURL,
        resourceName,
        canCreate,
        createLabel,
        tableConfig,
        editLabel,
        empty
    } = props;

    const {
        total
    } = useListContext();

    const {reference, target, filter} = tableConfig


    return (
        <div>
            {empty || total || canCreate ? (<><div className={combinedClasses.title}>{label}</div></>) : null}
            {empty || total  ? (<Datagrid empty={empty} classes={{table: combinedClasses.datagridTable}} rowClick={rowClick}>
                {tableFields.map((Line) => Line)}
                {canEdit && allowEdit && <EditButton
                    // cellClassName={classes.controlButton}
                    className={combinedClasses.controlButton}
                    basePath={`${baseURL}/${resourceName}`}
                />}
                {canDelete && allowDelete && <DeleteButton
                    // cellClassName={classes.controlButton}
                    className={combinedClasses.controlButton}
                    undoable={false}
                    redirect={baseURL}
                />}
            </Datagrid>) : null}

             {canCreate && <CreateButton
                label={createLabel}
                resource={reference}
                reference={reference}
                baseURL={baseURL}
                resourceName={resourceName}
            />}

            {canEdit && allowEdit && <EditRoute
                {...props}
                editLabel={editLabel}
                baseURL={baseURL}
                resource={reference}
            />}

            {canCreate && <CreateRoute
                {...props}
                createLabel={createLabel}
                baseURL={baseURL}
                resource={reference}
            />}
        </div>
    );
}

export const EditReferenceFields = (props) => {
    const {
        label,
        resource,
        tableConfig,
        baseProps,
        classes = {},
        baseUrl = null,
        empty
    } = props;
    const {reference, target, filter} = tableConfig
    const combinedClasses = {...useStyles(), ...classes}
    const {loading, actions} = useResourcePermissions(resource)

    if (loading) {
        return null
    }

    const baseURL = baseUrl !== null && baseUrl !== undefined ? baseUrl : `${baseProps.basePath}/${baseProps.id}`;

    const canCreate = actions.includes('create')
    const canEdit = actions.includes('edit')
    const canDelete = actions.includes('delete')

    return (
        <div>
            <ReferenceManyField
                perPage={10000}
                label={label}
                reference={reference}
                target={target}
                filter={filter}
            >
                <List
                    title={" "}
                    empty={false}
                    exporter={false}
                    actions={false}
                    pagination={false}
                    bulkActionButtons={false}
                    className={combinedClasses.table}
                    classes={{content: combinedClasses.content}}
                >
                    <ListContent combinedClasses={combinedClasses} baseURL={baseURL} canCreate={canCreate} canEdit={canEdit} canDelete={canDelete} {...props} />
                </List>
            </ReferenceManyField>
        </div>
    );
};

EditReferenceFields.defaultProps = {
    allowDelete: true,
    allowEdit: true,
    empty: false
};

