import React from "react"
import {List, Datagrid, TextField, Filter, FunctionField} from "react-admin"
import {useResourcePermissions} from "../../../utils/useResourcePermissions"
import filterCreators from "../../../utils/filterCreators"
import {TablePagination} from '../../../components/TablePagination/TablePagination'
import i18nProvider from "../../../providers/i18nProvider";

const {translate} = i18nProvider

const TerminalFilters = (props) => (
  <Filter {...props}>
    {filterCreators.createSearchInput("search")}
  </Filter>
)

const TerminalList = (props) => {
  const {loading, actions} = useResourcePermissions(props.resource)

  if (loading) {
    return null
  }

  const newProps = {...props}
  newProps.hasCreate = actions.includes('create')

  return (
    <List
      {...newProps}
      filters={<TerminalFilters/>}
      empty={false}
      exporter={false}
      sort={{field: 'id', order: 'DESC'}}
      pagination={<TablePagination/>}
      perPage={TablePagination.defaultPerPage}
    >
      <Datagrid rowClick="edit">
        <TextField source="id"/>
        <TextField source="data.portal_id"/>
        <FunctionField
            source='status'
            render={(record) => record && record.id ? translate(`resources.fbp_pricing/terminal.statuses.${record.status}`) : ''}
           />
      </Datagrid>
    </List>
  )
}

export default TerminalList
