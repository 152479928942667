import * as React from 'react'
import {Children, cloneElement, isValidElement, useState} from 'react'
import PropTypes from 'prop-types'
import {useTranslate, useGetIdentity} from 'ra-core'
import {
  Menu,
  Button,
  Avatar,
} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import AccountCircle from '@material-ui/icons/AccountCircle'

const defaultIcon = <AccountCircle/>

const useStyles = makeStyles(
  theme => ({
    user: {},
    userButton: {
      textTransform: 'none',
      padding: `6px 12px`,
    },
    avatar: {
      width: theme.spacing(3.5),
      height: theme.spacing(3.5),
      backgroundColor: "rgba(255, 255, 255, 0.7)",
    },
    info: {
      display: "flex",
      alignItems: "flex-end",
      flexDirection: "column",
    },
    name: {
      height: 15,
      fontWeight: 600,
      fontSize: 12,
    },
    role: {
      opacity: .7,
      height: 15,
      fontSize: 11,
    }
  }),
  {name: 'RaUserMenu'}
)

const AnchorOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
}

const TransformOrigin = {
  vertical: 'top',
  horizontal: 'right',
}

const UserMenu = (props) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const translate = useTranslate()
  const {loaded, identity} = useGetIdentity()
  const classes = useStyles(props)

  const {
    children,
    label = 'ra.auth.user_menu',
    icon = defaultIcon,
    logout,
  } = props

  if (!logout && !children) return null
  const open = Boolean(anchorEl)

  const handleMenu = event => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  const fullName = loaded && (identity.username) ? `${identity.username}` : ''

  return (
    <div className={classes.user}>
      {loaded && (
        <Button
          aria-label={label && translate(label, {_: label})}
          className={classes.userButton}
          color="inherit"
          endIcon={<Avatar className={classes.avatar}/>}
          onClick={handleMenu}
        >
          <div className={classes.info}>
            <div className={classes.name}>
              {fullName}
            </div>
            <div className={classes.role}>
              {translate(`roles.${identity.role}`)}
            </div>
          </div>
        </Button>
      )}
      {/*{loaded && identity.fullName ? (*/}
      {/*  <Button*/}
      {/*    aria-label={label && translate(label, {_: label})}*/}
      {/*    className={classes.userButton}*/}
      {/*    color="inherit"*/}
      {/*    startIcon={*/}
      {/*      identity.avatar ? (*/}
      {/*        <Avatar*/}
      {/*          className={classes.avatar}*/}
      {/*          src={identity.avatar}*/}
      {/*          alt={identity.fullName}*/}
      {/*        />*/}
      {/*      ) : (*/}
      {/*        icon*/}
      {/*      )*/}
      {/*    }*/}
      {/*    onClick={handleMenu}*/}
      {/*  >*/}
      {/*    {identity.fullName}*/}
      {/*  </Button>*/}
      {/*) : (*/}
      {/*  <Tooltip title={label && translate(label, {_: label})}>*/}
      {/*    <IconButton*/}
      {/*      aria-label={label && translate(label, {_: label})}*/}
      {/*      aria-owns={open ? 'menu-appbar' : null}*/}
      {/*      aria-haspopup={true}*/}
      {/*      color="inherit"*/}
      {/*      onClick={handleMenu}*/}
      {/*    >*/}
      {/*      {icon}*/}
      {/*    </IconButton>*/}
      {/*  </Tooltip>*/}
      {/*)}*/}
      <Menu
        id="menu-appbar"
        disableScrollLock
        anchorEl={anchorEl}
        anchorOrigin={AnchorOrigin}
        transformOrigin={TransformOrigin}
        // Make sure the menu is display under the button and not over the appbar
        // See https://v4.mui.com/components/menus/#customized-menus
        getContentAnchorEl={null}
        open={open}
        onClose={handleClose}
      >
        {Children.map(children, menuItem =>
          isValidElement(menuItem)
            ? cloneElement(menuItem, {
              onClick: handleClose,
            })
            : null
        )}
        {logout}
      </Menu>
    </div>
  )
}

UserMenu.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  label: PropTypes.string,
  logout: PropTypes.element,
  icon: PropTypes.node,
}

export default UserMenu
