import React from "react"
import {Create, SimpleForm, ReferenceInput, SelectInput, useTranslate, NumberInput} from "react-admin"
import {terminalStatusesChoices} from "../../../configs/terminalStatuses"
import {JsonEditor} from "../../../components/fields/JsonEditor/JsonEditor"
import {validation} from "../../../utils/validators"
import {makeStyles} from "@material-ui/core/styles"

const useStyles = makeStyles({
  plane: {
    width: '100%',
    maxWidth: 840,
  },
  editor: {
    minWidth: 450,

    '& .MuiInputBase-root': {
      overflow: "hidden",
      resize: "both",
    }
  },
  simpleForm: {
    maxWidth: 400,
  }
})

const TerminalCreate = (props) => {
  const translate = useTranslate()
  const classes = useStyles()

  return (
    <Create
      title={`${translate('resources.fbp_pricing/terminal.create')}`}
      {...props}
    >
      <SimpleForm variant="outlined"
        className={classes.simpleForm}
      >
        {/*<Box fullWidth className={classes.plane}>*/}
        <ReferenceInput
          source="payment_gate"
          reference="fbp_pricing/paymentgate"
          fullWidth
        >
          <SelectInput fullWidth optionText="iname"/>
        </ReferenceInput>

        <ReferenceInput
          source="terminal_owner"
          reference="fbp_pricing/terminalowner"
          fullWidth
        >
          <SelectInput fullWidth allowEmpty optionText="iname" emptyText="wtf!"/>
        </ReferenceInput>

        <SelectInput
            fullWidth
          source="status"
          optionText="name"
          optionValue="id"
          choices={terminalStatusesChoices}
        />

        <NumberInput fullWidth source="current_day_amount" disabled={true}/>
        <NumberInput fullWidth source="current_day_count" disabled={true}/>
        <NumberInput fullWidth source="current_month_amount" disabled={true}/>
        <NumberInput fullWidth source="current_month_count" disabled={true}/>

        <NumberInput fullWidth source="min_amount"/>
        <NumberInput fullWidth source="limit_card_day_amount"/>
        <NumberInput fullWidth source="limit_card_day_count"/>
        <NumberInput fullWidth source="limit_card_withdrawal_day_amount"/>
        <NumberInput fullWidth source="limit_card_withdrawal_day_count"/>
        <NumberInput fullWidth source="limit_day_amount"/>
        <NumberInput fullWidth source="limit_day_count"/>
        <NumberInput fullWidth source="limit_month_amount"/>
        <NumberInput fullWidth source="limit_month_count"/>

        <JsonEditor
          overrideOptions={{
              wordWrap: 'on',
          }}
          className={classes.editor}
          validate={validation.composeValidators(validation.required, validation.json)}
          label="Data"
          source="data"
        />

        {/*</Box>*/}
      </SimpleForm>
    </Create>
  )
}

export default TerminalCreate
