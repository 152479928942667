import lists from "../../components/lists"
import PaymentsIcon from "@mui/icons-material/Payments"
import React from "react"

export const invoice = {
  name: 'fbp_pricing/invoice',
  // options: {label: "Payments"},
  icon: PaymentsIcon,
  list: lists.PaymentsList,
}
