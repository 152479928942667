import React from 'react'
import {Route} from "react-router-dom"
import ChangePasswordEdit from "./components/edit/ChangePasswordEdit"
import Login from "./layout/Login"
import { FAQ } from "./pages/FAQ";
import { BalancesPage } from "./pages/BalancesPage"
import {ChartsPage} from './pages/ChartsPage'

export const routes = [
  <Route
    exact
    path="/login"
    component={() => <></>}
    noLayout
  />,
  <Route
    exact
    path="/merchant/login"
    component={Login}
    noLayout
  />,
  <Route
    exact
    path="/faq"
    component={FAQ}
  />,
  <Route
    exact
    path="/balances"
    component={BalancesPage}
  />,
  // <Route
  //   exact
  //   path="/charts"
  //   component={ChartsPage}
  // />,
  <Route
    exact
    path="/change-password"
    component={ChangePasswordEdit}
  />,
]
