import React from 'react'
import {Layout} from 'react-admin'
import AppBar from './AppBar'
import Menu from './Menu'
import {AppSideBar, SideBar} from "./SideBar";

export default props => (
  <Layout
    {...props}
    appBar={AppBar}
    menu={Menu}
    sidebar={AppSideBar}
  />
)
