import {applyMiddleware, combineReducers, compose, createStore} from 'redux'
import {routerMiddleware, connectRouter} from 'connected-react-router'
import createSagaMiddleware from 'redux-saga'
import {all, fork} from 'redux-saga/effects'
import {
  adminReducer,
  adminSaga,
  USER_LOGOUT,
} from 'react-admin'
import rootSagas from "./rootSagas"
import {routinePromiseWatcherSaga} from "redux-saga-routines"
import {balanceReducer} from "./balance/balanceSlice"
import {settingsReducer} from "./settings/settingsSlice"

let _store = null

export const app = {
  get store() {return _store},
}

export default ({authProvider, dataProvider, history}) => {

  const reducer = combineReducers({
    balance: balanceReducer,
    admin: adminReducer,
    settings: settingsReducer,
    router: connectRouter(history),
    // add your own reducers here
  })

  const resettableAppReducer = (state, action) =>
    reducer(action.type !== USER_LOGOUT ? state : undefined, action)

  const saga = function* rootSaga() {
    yield all(
      [
        adminSaga(dataProvider, authProvider),
        rootSagas,
        routinePromiseWatcherSaga,
        // add your own sagas here
      ].map(fork)
    )
  }
  const sagaMiddleware = createSagaMiddleware()

  const composeEnhancers =
    (process.env.NODE_ENV === 'development' &&
      typeof window !== 'undefined' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25,
      })) ||
    compose

  const store = createStore(
    resettableAppReducer,
    { /* set your initial state here */},
    composeEnhancers(
      applyMiddleware(
        sagaMiddleware,
        routerMiddleware(history),
        // add your own middlewares here
      ),
      // add your own enhancers here
    ),
  )
  sagaMiddleware.run(saga)

  _store = store
  return store
}
