import lists from "../../components/lists"
import React from "react"
import SummarizeIcon from '@mui/icons-material/Summarize'

export const registryfile = {
  name: 'sbank/registryfile',
  icon: SummarizeIcon,
  list: lists.RegistriesList,
}

export const registryTypes = {
  invoice: {
    name: 'invoice',
  },
  withdrawal: {
    name: 'withdrawal',
  }
}

export const registryTypesList = [
  'invoice',
  'withdrawal',
]