import {memo} from "react"
import Typography from "@material-ui/core/Typography"
import {FormControl, FormLabel, makeStyles, MenuItem, Select} from "@material-ui/core"
import {useDispatch, useSelector} from "react-redux"
import {setTheme} from "../../store/settings/settingsSlice";

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    margin: '6px 0',
    width: '100%',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  group: {},
  formGroupTitle: {
    position: 'absolute',
    top: -10,
    left: 8,
    fontWeight: 600,
    padding: '0 4px',
    backgroundColor: theme.palette.background.paper,
  },
  formGroup: {
    position: 'relative',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 2,
    padding: '12px 12px 0 12px',
    margin: '24px 0 16px 0',
    '&:first-of-type': {
      marginTop: 16,
    },
  },
}))

function Settings(props) {
  const classes = useStyles(props)
  const themes = useSelector(({settings}) => settings.themes)
  const dispatch = useDispatch()

  let value = 'default'

  const onChange = (e) => {
    const {value, name} =  e.target
    dispatch(setTheme({name, value}))
  }

  const ThemeSelect = ({value, name, handleThemeChange}) => {
    return (
      <Select
        className="w-full rounded-8 h-40 overflow-hidden my-8"
        value={value}
        onChange={handleThemeChange}
        name={name}
        variant="outlined"
        style={{
          backgroundColor: themes[value].palette.background.default,
          color: themes[value].palette.type === 'light' ? '#000000' : '#ffffff',
        }}
      >
        {Object.entries(themes)
          .filter(
            ([key, val]) =>
              !(name === 'theme.main' && (key === 'mainThemeDark' || key === 'mainThemeLight'))
          )
          .map(([key, val]) => (
            <MenuItem
              key={key}
              value={key}
              className="m-8 mt-0 rounded-lg"
              style={{
                backgroundColor: val.palette.background.default,
                color: val.palette.type === 'light' ? '#000000' : '#FFFFFF',
                border: `1px solid ${
                  val.palette.type === 'light' ? 'rgba(0, 0, 0, 0.12)' : 'rgba(255, 255, 255, 0.12)'
                }`,
              }}
            >
              {/*{_.startCase(key)}*/}
              <div
                className="flex w-full h-8 block absolute bottom-0 left-0 right-0"
                style={{
                  borderTop: `1px solid ${
                    val.palette.type === 'light'
                      ? 'rgba(0, 0, 0, 0.12)'
                      : 'rgba(255, 255, 255, 0.12)'
                  }`,
                }}
              >
                <div
                  className="w-1/4 h-8"
                  style={{
                    backgroundColor: val.palette.primary.main
                      ? val.palette.primary.main
                      : val.palette.primary[500],
                  }}
                />
                <div
                  className="w-1/4 h-8"
                  style={{
                    backgroundColor: val.palette.secondary.main
                      ? val.palette.secondary.main
                      : val.palette.secondary[500],
                  }}
                />
                <div
                  className="w-1/4 h-8"
                  style={{
                    backgroundColor: val.palette.error.main
                      ? val.palette.error.main
                      : val.palette.error[500],
                  }}
                />
                <div
                  className="w-1/4 h-8"
                  style={{backgroundColor: val.palette.background.paper}}
                />
              </div>
            </MenuItem>
          ))}
      </Select>
    )
  }

  return (
    <div className={classes.root}>

      <div className={`${classes.formGroup} pb-16`}>
        <Typography className={classes.formGroupTitle} color="textSecondary">
          Theme
        </Typography>

        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel component="legend" className="text-14">
            Main
          </FormLabel>
          <ThemeSelect value={value} handleThemeChange={onChange} name="main"/>
        </FormControl>

        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel component="legend" className="text-14">
            Navbar
          </FormLabel>

          <ThemeSelect value={value} handleThemeChange={onChange} name="navbar"/>
        </FormControl>

        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel component="legend" className="text-14">
            Toolbar
          </FormLabel>

          <ThemeSelect value={value} handleThemeChange={onChange} name="toolbar"/>
        </FormControl>
      </div>
    </div>
  )
}

export default memo(Settings)
