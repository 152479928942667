import {FunctionField} from "react-admin";
import React from "react";
import i18nProvider from "../../../providers/i18nProvider";

const {translate} = i18nProvider

const TranslatedField = (props) => {
    const {
        translationKey,
        field,
        record
    } = props;

    const translationKeyFull = `${translationKey}.${record[field]}`;
    let translation = translate(`${translationKey}.${record[field]}`);

    if (!record[field]) {
        translation = '';
    } else if (translationKeyFull === translation) {
        translation = record[field];
    }

    return translation;
}


export default TranslatedField;