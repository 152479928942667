import {all, put, takeEvery} from 'redux-saga/effects'
import {createRoutine} from 'redux-saga-routines'
import dataProvider from "../../providers/dataProvider"

export const fetchBalanceRoutine = createRoutine('fetchBalance')

function* fetchBalance() {
  try {
    yield put(fetchBalanceRoutine.request())

    const [accounts, accountTypes] = yield all([
      dataProvider.getList('fbp_ledger/account', {}),
      dataProvider.getList('fbp_ledger/accounttype', {}),
    ])

    const merchantBalance = accountTypes.data.find(accountType => accountType.iname === "merchant_balance")

    if (!merchantBalance) {
      throw new Error('merchant_balance account type dont exists.')
    }

    const balance = accounts.data.find(account => merchantBalance.id === account.account_type)

    if (!balance) {
      throw new Error('merchant dont have balance.')
    }

    yield put(fetchBalanceRoutine.success(balance))
  } catch (error) {
    yield put(fetchBalanceRoutine.failure(error.message))
    throw error
  } finally {
    yield put(fetchBalanceRoutine.fulfill())
  }
}

export default function* balanceSagas() {
  yield all([
    yield takeEvery(fetchBalanceRoutine.TRIGGER, fetchBalance),
  ])
}

