import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  ReferenceField,
  SelectInput,
  useTranslate,
  SaveButton,
  FileInput,
  FileField,
  Toolbar, BooleanInput, TextField, AutocompleteInput
} from "react-admin";
import DownloadField from "../fields/DownloadField/DownloadField";
import {JsonEditor} from "../fields/JsonEditor/JsonEditor";
import {PermissionField} from "../PermissionField/PermissionField";
import {validation} from "../../utils/validators";
import {addPermissionLabel} from "../../utils/addPermissionLabel";
import {Box, Stack} from '@mui/material'
import DeleteButtonWithConfirmation from '../DeleteButtonWithConfirmation/DeleteButtonWithConfirmation'
import {makeStyles} from "@material-ui/core/styles";
import {EditReferenceFields} from "../EditReferenceFields/EditReferenceFields";
import i18nProvider from "../../providers/i18nProvider";
import {commission_objects_choices} from "../../configs/commissionObjects";
import {CurrencyField} from "../fields/CurrencyField/CurrencyField";
import {useResourcePermissions} from "../../utils/useResourcePermissions";
import BulkWithdrawalsList from "../lists/BulkWithdrawalsList";

const {translate} = i18nProvider;

const items = {
    tableFields: [
        <TextField key="amount" source="amount" sortable={false}/>,
        <TextField key="amount" source="currency_code" sortable={false}/>,        
        <TextField key="status" source="status" sortable={false}/>,
    ]
};

const useProductCommissionStyles = makeStyles({
    table: {
        maxWidth: 840,
        marginBottom: 6
    }
})

const PostCreateToolbar = (props) => {
    return (
        <Toolbar>
            <Box
                sx={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
            >
                <SaveButton {...props} />
                <DeleteButtonWithConfirmation {...props} />
            </Box>
        </Toolbar>
    );
};

const useStyles = makeStyles({
    data: {
      maxWidth: '840px'
    },
    switchButtonsContainer: {
        minWidth: 840,
        marginTop: 15,
        '& .switch-buttons-wrapper': {
            marginTop: 10,
            padding: '20px 16px 0',
            'box-shadow': '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
            'border-radius': 4
        },
        '& .switch-buttons-title': {
            fontSize: 24,
            marginBottom: 5,
        }
    }
});

const BulkWithdrawalEdit = (props) => {
  const translate = useTranslate()
  const {id, resource} = props
  const classes = useStyles();
  const productCommissionStyles = useProductCommissionStyles();

  return (
      <>
        <Edit
            title={`${translate('resources.fbp_pricing/bulkwithdrawal.edit')}`}
            {...props}
        >
          <SimpleForm variant="outlined"
              toolbar={<PostCreateToolbar/>}
          >

          <ReferenceField
            source="merchant"
            reference="fbp_pricing/merchant"
            link={false}
          >
            <TextField source="iname"/>
          </ReferenceField>

          <ReferenceField
            source="endpoint"
            reference="fbp_pricing/product"
            link={false}
          >
            <TextField source="name"/>
          </ReferenceField>

          <DownloadField source="registry" label={translate('resources.fbp_pricing/bulkwithdrawal.download_registry')} />
          <DownloadField source="registry_result" label={translate('resources.fbp_pricing/bulkwithdrawal.download_registry_result')} />

          </SimpleForm>
        </Edit>
      </>
  );
}

export default BulkWithdrawalEdit;