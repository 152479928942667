import CryptoJS from "crypto-js";

export const sign = (payload, secret, dataParams) => {
  const header = {
    'alg': 'HS256'
  };

  const fields = Object.keys(payload).sort()
  fields.forEach(key => dataParams.POST[key] = payload[key])

  const base64url = (source) => {
    let encodedSource = CryptoJS.enc.Base64.stringify(source)
    encodedSource = encodedSource.replace(/=+$/, '')
    encodedSource = encodedSource.replace(/\+/g, '-')
    encodedSource = encodedSource.replace(/\//g, '_')
    return encodedSource
  }

  const stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header))
  const encodedHeader = base64url(stringifiedHeader)
  const stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(dataParams))
  const encodedData = base64url(stringifiedData)
  const token = `${encodedHeader}.${encodedData}`

  const signature = CryptoJS.HmacSHA256(token, secret)
  return base64url(signature)
}