import React from "react"
import {
    Edit,
    SimpleForm,
    TextInput,
    BooleanInput,
    TextField,
    ReferenceInput,
    SelectInput,
    AutocompleteInput,
    PasswordInput,
    useQuery,
    useEditController,
    Toolbar,
    SaveButton,
    useTranslate,
    FunctionField,
} from "react-admin"
import {makeStyles} from '@material-ui/core/styles'
import {EditReferenceFields} from "../EditReferenceFields/EditReferenceFields"
import {validation} from "../../utils/validators"
import Typography from "@material-ui/core/Typography"
import {LinearProgress, Stack} from "@mui/material"
import {useDataProvider} from "ra-core"
import {Box} from "@material-ui/core"
import {PermissionField} from "../PermissionField/PermissionField"
import i18nProvider from "../../providers/i18nProvider"
import {HelpInfo} from "../HelpInfo/HelpInfo"
import {addPermissionLabel} from "../../utils/addPermissionLabel"
import {commission_objects_choices} from "../../configs/commissionObjects";
import {JsonEditor} from "../fields/JsonEditor/JsonEditor";
import {SimpleEmpty} from "../table/SimpleEmpty";

const validateFields = {
    iname: validation.composeValidators(validation.required),
}

function validate(values) {
    const errors = {}
    Object.entries(validateFields).forEach(([field, v]) => errors[field] = v(values[field]))
    return errors
}

// const url = {
//   createFormFields: [
//     <ReferenceInput fullWidth label="Url type" source="merchant_url_type" reference="fbp_pricing/merchanturltype">
//       <SelectInput optionText="iname"/>
//     </ReferenceInput>,
//     <TextInput variant="outlined" fullWidth source="url"/>,
//   ],
//   editFormFields: [
//     <ReferenceInput fullWidth label="Url type" source="merchant_url_type" reference="fbp_pricing/merchanturltype">
//       <SelectInput optionText="iname"/>
//     </ReferenceInput>,
//     <TextInput variant="outlined" fullWidth source="url"/>,
//   ],
//   tableFields: [
//     <ReferenceField
//       key="merchant_url_type"
//       label="url type"
//       source="merchant_url_type"
//       reference="fbp_pricing/merchanturltype"
//       link={false}
//       sortable={false}
//     >
//       <TextField source="iname"/>
//     </ ReferenceField>,
//     <TextField key="url" source="url" sortable={false}/>,
//   ],
// }
//
const {translate} = i18nProvider

const commission = {
    createFormFields: [
        <AutocompleteInput
            label={translate(`resources.fbp_pricing/merchantcommission.fields.commission_object`)}
            fullWidth
            source="commission_object"
            choices={commission_objects_choices}
            optionText="name"
            optionValue="iname"
        />,
        <TextInput variant="outlined" fullWidth label={translate(`resources.fbp_pricing/merchantcommission.fields.min_amount`)}
                   source="min_amount"/>,
        <TextInput variant="outlined" label={translate(`resources.fbp_pricing/merchantcommission.fields.fix_amount`)} fullWidth
                   source="fix_amount"/>,
        <TextInput variant="outlined" label={translate(`resources.fbp_pricing/merchantcommission.fields.percent`)} fullWidth
                   source="percent"/>,
        <ReferenceInput
            label={translate(`resources.fbp_pricing/merchantcommission.fields.commission_type`)}
            fullWidth source="commission_type" reference="fbp_pricing/commissiontype">
            <SelectInput optionText="iname"/>
        </ReferenceInput>,
    ],
    editFormFields: [
        <AutocompleteInput
            label={translate(`resources.fbp_pricing/merchantcommission.fields.commission_object`)}
            fullWidth source="commission_object"
            choices={commission_objects_choices}
            optionText="name"
            optionValue="iname"
        />,
        <TextInput variant="outlined" fullWidth label={translate(`resources.fbp_pricing/merchantcommission.fields.min_amount`)}
                   source="min_amount"/>,
        <TextInput variant="outlined" label={translate(`resources.fbp_pricing/merchantcommission.fields.fix_amount`)} fullWidth
                   source="fix_amount"/>,
        <TextInput variant="outlined" label={translate(`resources.fbp_pricing/merchantcommission.fields.percent`)} fullWidth
                   source="percent"/>,
        <ReferenceInput
            label={translate(`resources.fbp_pricing/merchantcommission.fields.commission_type`)}
            fullWidth source="commission_type" reference="fbp_pricing/commissiontype">
            <SelectInput optionText="iname"/>
        </ReferenceInput>,
    ],
    tableFields: [
        <FunctionField
            source='commission_object'
            render={record => translate(`resources.fbp_pricing/merchantcommission.commission_types.${record.commission_object}`)}
            sortable={false}
        />,
        <TextField key="min_amount" source="min_amount" sortable={false}/>,
        <TextField key="fix_amount" source="fix_amount" sortable={false}/>,
        <TextField key="percent" source="percent" sortable={false}/>,
    ],
}

const products = {
    tableFields: [
        <TextField key="id" source="id" sortable={false}/>,
        <TextField key="name" source="name" sortable={false}/>,
        <FunctionField
            key='is_available_for_invoice'
            source='is_available_for_invoice'
            render={record => translate(`common.${record.is_available_for_invoice ? 'yes' : 'no'}`)}
            sortable={false}
        />,
        <FunctionField
            key='is_available_for_withdrawal'
            source='is_available_for_withdrawal'
            render={record => translate(`common.${record.is_available_for_withdrawal ? 'yes' : 'no'}`)}
            sortable={false}
        />
    ],
}

const useStyles = makeStyles({
    plane: {
        width: '100%',
        maxWidth: 840,
    },
    simpleForm: {
        "& .ra-input-id": {
            paddingBottom: "15px",
            "& label": {
                fontSize: "40px!important"
            }
        }
    },
    dataTitle: {
        fontSize: 24,
        marginBottom: 12
    },
    data: {
        marginBottom: 16
    },
    switchButtonsContainer: {
        marginTop: 15,
        '& .switch-buttons-wrapper': {
            marginTop: 10,
            padding: '20px 16px 0',
            'box-shadow': '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
            'border-radius': 4
        },
        '& .switch-buttons-title': {
            fontSize: 24,
            marginBottom: 5,
        }
    }
});

const UserEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton/>
    </Toolbar>
)

const MerchantsEdit = (props) => {
    const {id, resource, basePath} = props
    const dataProvider = useDataProvider()
    const classes = useStyles()
    const translate = useTranslate()

    const queryUrl = useQuery({
        type: 'getList',
        resource: "fbp_pricing/merchanturl",
        payload: {
            filter: {merchant: id},
            pagination: {page: 1, perPage: 1e3},
            sort: {field: 'id'}
        },
    })

    const queryUrlType = useQuery({
        type: 'getList',
        resource: "fbp_pricing/merchanturltype",
        payload: {
            filter: {merchant: id},
            pagination: {page: 1, perPage: 1e3},
            sort: {}
        },
    })

    const controllerProps = useEditController({
        ...props,
        basePath,
        resource,
        id,
        undoable: false,
    })

    const save = controllerProps.save
    controllerProps.save = (data, redirectTo, _a = {}) => {
        const newData = {}
        const requests = []

        const urls = {}

        Object.keys(data).forEach(field => {
            const value = data[field]

            if (field.includes('|')) {
                const math = /^([\w]+)\|([\w]+)$/.exec(field)
                if (math.length === 3) {
                    const typeID = Number(math[1])
                    urls[typeID] = value
                    return
                }
            }
            newData[field] = value
        })

        queryUrl.data.forEach(url => {
            if (!urls[url.merchant_url_type]) {
                urls[url.merchant_url_type] = ""
            }
        })

        Object.keys(urls).forEach(typeID => {
            const value = urls[typeID]
            if (queryUrlType.data?.find(type => type.id === Number(typeID))) {
                const record = queryUrl.data?.find(url => Number(url.merchant_url_type) === Number(typeID))
                if (record) {
                    if (record.url === value) {
                        return
                    }
                    record.url = value
                    requests.push(dataProvider.update('fbp_pricing/merchanturl', {
                        id: record.id,
                        data: record,
                    }))
                } else {
                    requests.push(dataProvider.create('fbp_pricing/merchanturl', {
                        data: {
                            merchant: id,
                            merchant_url_type: typeID,
                            url: value,
                        },
                    }))
                }
            }
        })

        Promise.all(requests).then(() => {
            // _a.onSuccess = () => {
            //   props.refreshView()
            //   redirect(redirectURL)
            // }
            save(newData, redirectTo)
        })
    }

    return (
        <>
            <Edit
                title={`${translate('resources.fbp_pricing/merchant.edit')}`}
                {...props}
            >
                <SimpleForm variant="outlined"
                    className={classes.simpleForm}
                    basePath={controllerProps.basePath}
                    record={controllerProps.record}
                    save={controllerProps.save}
                    version={controllerProps.version}
                    toolbar={<UserEditToolbar/>}
                    validate={validate}
                >
                    <Box fullWidth className={classes.plane}>
                        <Stack sx={{width: '50%'}}>
                            <TextInput variant="outlined" label={translate(`resources.fbp_pricing/merchant.fields.iname`)} source="iname"/>
                            <PasswordInput variant="outlined" label={translate(`resources.fbp_pricing/merchant.fields.api_secret`)}
                                           source="api_secret"/>
                            <TextInput variant="outlined" label={translate(`resources.fbp_pricing/merchant.fields.primary_email`)}
                                       source="primary_email"/>
                        </Stack>

                        <Typography variant="h5" component="h5">
                            {translate('resources.fbp_pricing/merchant.fields.urls')}
                        </Typography>

                        {queryUrl.loaded && queryUrlType.loaded ? (
                            <Stack
                                sx={{width: '50%'}}
                            >
                                {queryUrlType.data.map(type => (
                                    <TextInput
                                        variant="outlined"
                                        key={type.id}
                                        InputProps={{
                                            endAdornment: <HelpInfo
                                                info={translate(`resources.fbp_pricing/merchant.urlTypeInfo.${type.iname}`)}/>,
                                        }}
                                        source={`${type.id}|${type.iname}`}
                                        initialValue={queryUrl.data?.find(url => url.merchant_url_type === type.id)?.url}
                                        label={`${type.iname[0].toUpperCase()}${type.iname.slice(1).replace('_', ' ')}`}
                                    />
                                ))}
                            </Stack>
                        ) : (
                            <Stack
                                sx={{width: '50%'}}
                                spacing={2}
                                my={1}
                            >
                                <LinearProgress/>
                            </Stack>
                        )}

                        <div className={classes.dataTitle}>
                            {translate('resources.fbp_pricing/merchant.fields.data')}
                        </div>

                        <PermissionField
                            resource={props.resource}
                            source="data"
                            addLabel={addPermissionLabel(resource, "data")}
                            fullWidth
                        >
                            <JsonEditor
                                className={classes.data}
                                validate={validation.composeValidators(validation.required, validation.json)}
                                initialSourceValue="data"
                                source="data"
                            />
                        </PermissionField>


                        {/*<EditReferenceFields*/}
                        {/*  label="Urls"*/}
                        {/*  baseProps={props}*/}
                        {/*  resourceName={"url"}*/}
                        {/*  tableConfig={{*/}
                        {/*    reference: "fbp_pricing/merchanturl",*/}
                        {/*    target: "url",*/}
                        {/*    filter: {merchant: id},*/}
                        {/*  }}*/}
                        {/*  tableFields={url.tableFields}*/}
                        {/*  createFormFields={url.createFormFields}*/}
                        {/*  editFormFields={url.editFormFields}*/}
                        {/*/>*/}

                        <EditReferenceFields
                            label={translate(`resources.fbp_pricing/merchantcommission.name`, {smart_count: 1})}
                            editLabel={translate(`resources.fbp_pricing/merchantcommission.edit`)}
                            createLabel={translate(`resources.fbp_pricing/merchantcommission.create`)}
                            baseProps={props}
                            resourceName={"commission"}
                            resource="fbp_pricing/merchantcommission"
                            tableConfig={{
                                reference: "fbp_pricing/merchantcommission",
                                target: "merchant",
                                filter: {merchant: id},
                            }}
                            tableFields={commission.tableFields}
                            createFormFields={commission.createFormFields}
                            editFormFields={commission.editFormFields}
                        />

                        <EditReferenceFields
                            label={translate(`resources.fbp_pricing/product.connected_products`, {smart_count: 1})}
                            editLabel={translate(`resources.fbp_pricing/product.edit`)}
                            createLabel={translate(`resources.fbp_pricing/product.create`)}
                            baseProps={props}
                            resourceName={"fbp_pricing/product"}
                            resource="fbp_pricing/product"
                            tableConfig={{
                                reference: "fbp_pricing/product",
                                target: "product",
                                filter: {merchant: id},
                            }}
                            tableFields={products.tableFields}
                            createFormFields={products.createFormFields}
                            editFormFields={products.editFormFields}
                            allowDelete={false}
                            allowEdit={false}
                            rowClick="edit"
                            baseUrl={''}
                            // empty={<SimpleEmpty text={translate(`resources.fbp_pricing/product.empty_connected_products`)} />}
                        />

                        <div className={classes.switchButtonsContainer}>
                            <div className="switch-buttons-title">{translate(`resources.fbp_pricing/merchant.additional_settings`)}</div>
                            <Box display={{xs: 'flex', sm: 'flex'}} className="switch-buttons-wrapper"
                                 flexDirection="column">

                                <Stack direction="row">
                                    <PermissionField
                                        resource={props.resource}
                                        source="is_test"
                                        addLabel={addPermissionLabel(resource, "is_test")}
                                    >
                                        <BooleanInput/>
                                    </PermissionField>

                                    <PermissionField
                                        resource={props.resource}
                                        source="is_available_for_invoice"
                                        addLabel={addPermissionLabel(resource, "is_available_for_invoice")}
                                    >
                                        <BooleanInput/>
                                    </PermissionField>

                                    <PermissionField
                                        resource={props.resource}
                                        source="is_available_for_withdrawal"
                                        addLabel={addPermissionLabel(resource, "is_available_for_withdrawal")}
                                    >
                                        <BooleanInput/>
                                    </PermissionField>
                                </Stack>
                            </Box>
                        </div>

                        {/*<JsonEditor*/}
                        {/*  validate={validation.composeValidators(validation.required, validation.json)}*/}
                        {/*  label="Data"*/}
                        {/*  initialSourceValue="data"*/}
                        {/*  source="data"*/}
                        {/*/>*/}
                    </Box>
                </SimpleForm>
            </Edit>
        </>
    )
}

export default MerchantsEdit
