import React from "react"
import {Create, SimpleForm, TextInput, BooleanInput, useTranslate, useEditController, useCreateController, useNotify} from "react-admin"
import {JsonEditor} from "../fields/JsonEditor/JsonEditor"
import {validation} from "../../utils/validators"
import {PermissionField} from "../PermissionField/PermissionField"
import {addPermissionLabel} from "../../utils/addPermissionLabel"
import {rawDataProvider} from '../../providers/dataProvider'
import {apiURL} from '../../utils/apiURL'
import {useHistory} from 'react-router-dom'

const validateFields = {
  iname: validation.composeValidators(validation.required),
  data: validation.composeValidators(validation.required, validation.json),
}

function validate(values) {
  const errors = {}
  Object.entries(validateFields).forEach(([field, v]) => errors[field] = v(values[field]))
  return errors
}

const MerchantCreate = (props) => {
  const translate = useTranslate()
  const {resource, basePath} = props
  const history = useHistory();
  const notify = useNotify();

  const controllerProps = useCreateController({
    ...props,
    basePath,
    resource,
    undoable: false,
  })

  controllerProps.save = (data, redirectTo, _a = {}) => {
    const res = rawDataProvider("CUSTOM_POST", `${apiURL}/create_merchant`, {
      ...data
    })
    res.then(async (data) => {
      if(data.json.status === 'success') {
        history.push(`/fbp_pricing/merchant/${data.json.id}`)
      }
    }).catch(async error => {
      if (error?.message) {
        notify(error?.message, 'error', null, false, 4000)
      }
    })
  }

  return (
    <>
      <Create
        title={`${translate('resources.fbp_pricing/merchant.create')}`}
        {...props}
      >
        <SimpleForm
            variant="outlined"
          initialValues={{data: {}}}
          basePath={controllerProps.basePath}
          record={controllerProps.record}
          save={controllerProps.save}
          version={controllerProps.version}
          validate={validate}
        >
          <TextInput variant="outlined" source="iname"/>

          <TextInput variant="outlined" source="api_secret"/>

          <TextInput variant="outlined" source="primary_email"/>

          {/*<TextInput source="username"/>*/}

          {/*<TextInput source="password"/>*/}

          <BooleanInput defaultChecked={true} source="is_test"/>

          <PermissionField
            resource={props.resource}
            source="data"
            addLabel={addPermissionLabel(resource, "data")}
            fullWidth
          >
            <JsonEditor
              validate={validation.composeValidators(validation.required, validation.json)}
              initialSourceValue="data"
              source="data"
            />
          </PermissionField>
        </SimpleForm>
      </Create>
    </>
  )
}

export default MerchantCreate
