import React from "react"
import {List, Datagrid, TextField, DateField, Filter, ReferenceField, useRedirect, FunctionField} from "react-admin"
import filterCreators from "../../../utils/filterCreators"
import Drawer from "@material-ui/core/Drawer"
import {Route, useHistory} from "react-router-dom"
import i18nProvider from "../../../providers/i18nProvider"
import {BeautifyCurrencyAmountField} from "../../../utils/currencyUtils"
import {DateRangeInput} from "../../../components/inputs/DateRangeInput/DateRangeInput"
import {useResourcePermissions} from "../../../utils/useResourcePermissions"
import {SettlementView} from "./SettlementView"
import {TablePagination} from '../../../components/TablePagination/TablePagination'
import {colorMapping, status_choices} from "../../../configs/withdrawalStatuses";
import StatusField from "../../../components/fields/StatusField/StatusField";

const dateFormat = (value) => {
  if (!value) {
    return value
  }
  return value.split('T')[0]
}

const dateParse = (value) => {
  if (!value) {
    return value
  }
  return value + 'T00:00:00+00:00'
}

const {translate} = i18nProvider

const SettlementsFilters = (props) => (
  <Filter variant="outlined"  {...props}>
    {filterCreators.createSearchInput("search")}
    <DateRangeInput
      startText={translate('filters.created_date_from')}
      endText={translate('filters.created_date_to')}
      label={translate('filters.search_by_create_date')}
      source={"created_at"}
      format={dateFormat}
      parse={dateParse}
    />
    {filterCreators.createReferenceInput(
      "fbp_pricing/merchant",
      translate('filters.search_by_merchants'),
      "merchant",
      "iname",
      false,
    )}
    {filterCreators.createReferenceInput(
      "fbp_pricing/product",
      translate('filters.search_by_product'),
      "primary_product",
      "name",
      false,
    )}
    {filterCreators.createSelectInput(
      "status",
        translate('filters.search_by_status'),
        status_choices
    )}
    {/*{filterCreators.createDateInput({source: "created_at", format: dateFormat, parse: dateParse})}*/}
  </Filter>
)

const SettlementsList = (props) => {
  const {resource} = props
  const redirect = useRedirect()
  const history = useHistory()
  const {actions: paymentGateActions} = useResourcePermissions(resource, 'primary_payment_gate')

  const pathname = '/sbank/settlement'
  const basePath = `${pathname}${props.location.search}`

  const drawerClose = () => redirect(basePath)
  const open = (id) => {
    history.push(`${pathname}/${id}${props.location.search}`)
  }

  return (
    <>
      <List
        {...props}
        empty={false}
        exporter={false}
        filters={<SettlementsFilters/>}
        bulkActionButtons={false}
        sort={{ field: 'created_at', order: 'DESC' }}
        pagination={<TablePagination/>}
        perPage={TablePagination.defaultPerPage}
      >
        <Datagrid rowClick={open}>
          <TextField source="id" sortable={false}/>
          <ReferenceField
            source="merchant"
            reference="fbp_pricing/merchant"
            link={false}
            sortable={false}
          >
            <TextField source="iname"/>
          </ReferenceField>

          <DateField source="created_at" showTime/>
          <DateField source="finished_at" showTime/>

          <StatusField colorMap={colorMapping}  translationKey={`resources.fbp_pricing/withdrawalrequest.statuses`} source="status"/>

          <BeautifyCurrencyAmountField source="amount" amountField="amount" codeField="currency_code"/>
          <BeautifyCurrencyAmountField source="fee_from_merchant" amountField="fee_from_merchant" codeField="currency_code"/>

          {paymentGateActions?.includes('view') && (
            <ReferenceField source="primary_payment_gate" reference="fbp_pricing/paymentgate" link={false}>
              <TextField source="iname"/>
            </ReferenceField>
          )}
        </Datagrid>
      </List>

      <Route path={`${pathname}/:id`}>
        {({match}) => {
          const isMatch = !!(match && match.params && match.params.id)
          return (
            <>
              <Drawer
                open={isMatch}
                anchor="right"
                onClose={drawerClose}
              >
                {isMatch ? (
                  <SettlementView
                    basePath={basePath}
                    resource={'sbank/settlement'}
                    id={match.params.id}
                    onCancel={drawerClose}
                  />
                ) : null}
              </Drawer>
            </>
          )
        }}
      </Route>
    </>
  )
}

export default SettlementsList
