import React from "react"
import {List, Datagrid, TextField, FunctionField, Filter, useTranslate} from "react-admin"
import {useResourcePermissions} from "../../utils/useResourcePermissions"
import filterCreators from "../../utils/filterCreators"
import { TablePagination } from "../TablePagination/TablePagination";

const MerchantFilters = (props) => (
  <Filter variant="outlined"  {...props}>
    {filterCreators.createSearchInput("search")}
  </Filter>
)

const MerchantsList = (props) => {
  const {loading, actions} = useResourcePermissions(props.resource)
  const translate = useTranslate()

  if (loading) {
    return null
  }

  const newProps = {...props}
  newProps.hasCreate = actions.includes('create')

  return (
    <List
      {...newProps}
      filters={<MerchantFilters/>}
      empty={false}
      exporter={false}
      sort={{ field: 'id', order: 'DESC' }}
      pagination={<TablePagination />}
      perPage={TablePagination.defaultPerPage}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="iname" />
        <FunctionField source="is_test" render={record => translate(`common.${record.is_test ? 'yes' : 'no'}`)} />
      </Datagrid>
    </List>
  )
}

export default MerchantsList
