import React from "react"
import AccountBalanceIcon from "@mui/icons-material/AccountBalance"
import TerminalownerList from "./TerminalownerList"
import TerminalownerEdit from "./TerminalownerEdit"
import TerminalownerCreate from "./TerminalownerCreate"

export const terminalowner = {
  name: 'fbp_pricing/terminalowner',
  icon: AccountBalanceIcon,
  edit: TerminalownerEdit,
  create: TerminalownerCreate,
  list: TerminalownerList,
}
