import {usePermissions} from "ra-core";
import {useStore} from "react-redux";
import {useRef} from "react";
import {allActions, roles} from "../configs/roles";

function createActions(actions) {
  switch (true) {
    case actions === '*':
      return [...allActions]
    case typeof actions === "string" :
      return [actions]
    case Array.isArray(actions):
      return [...actions]
  }
}

function createField(resource) {
  return {
    actions: [...resource.actions]
  }
}

const cache = {}

export function useResourcePermissions(resource, field) {
  const {loading, loaded, permissions: userRoles} = usePermissions()
  const store = useStore()
  const ref = useRef()
  const {current} = ref

  cache.ref = ref
  cache.loading = loading
  cache.loaded = loaded

  if (loading) {
    return {loading, loaded}
  }

  if (!current) {
    const permissions = {}
    ref.current = permissions

    const rules = roles[userRoles[0]]
    const resources = Object.keys(store.getState().admin.resources)

    rules.allow?.forEach(rule => {
      rule.regexp = new RegExp(`${rule.resource.replaceAll('*', '[\\w\\\\]+')}`)
    })

    rules.deny?.forEach(rule => {
      rule.regexp = new RegExp(`${rule.resource.replaceAll('*', '[\\w\\\\]+')}`)
    })

    resources.forEach(resource => {
      permissions[resource] = {
        actions: [],
        fields: {},
      }
    })

    resources.forEach(resource => {

      rules.allow?.forEach(rule => {
        const math = rule.regexp.exec(resource)
        if (!math) {
          return
        }

        if (rule.fields) {
          rule.fields.forEach(field => {
            console.log(field)
          })
          return;
        }

        permissions[resource].actions = createActions(rule.actions)
      })

      rules.deny?.forEach(rule => {
        const math = rule.regexp.exec(resource)
        if (!math) {
          return
        }

        if (rule.fields) {
          rule.fields.forEach(fieldName => {
            if (!permissions[resource].fields[fieldName]) {
              permissions[resource].fields[fieldName] = createField(permissions[resource])
            }
            const field = permissions[resource].fields[fieldName]

            rule.actions.forEach(action => {
              const index = field.actions.indexOf(action)
              if (index >= 0) {
                field.actions.splice(index, 1);
              }
            })
          })
          return
        }

        const field = permissions[resource]
        rule.actions.forEach(action => {
          const index = field.actions.indexOf(action)
          if (index >= 0) {
            field.actions.splice(index, 1);
          }
        })

      })
    })
  }

  if(!resource) {
    return {
      loading,
      loaded,
      actions: ref.current,
    }
  }

  if(field) {
    const f = ref.current[resource].fields[field]
    return {
      loading,
      loaded,
      actions: f ? f.actions : ref.current[resource].actions,
    }
  }

  return {
    loading,
    loaded,
    fields: ref.current[resource].fields,
    actions: ref.current[resource].actions,
  }
}

export function getResourcePermissions(resource, field) {
  const {ref, loading, loaded} = cache

  if(loading) {
    return {loading, loaded}
  }

  if(!resource) {
    return {
      loading,
      loaded,
      actions: ref.current,
    }
  }

  if (field) {
    const f = ref.current[resource].fields[field]
    return {
      loading,
      loaded,
      actions: f ? f.actions : ref.current[resource].actions,
    }
  }

  return {
    loading,
    loaded,
    fields: ref.current[resource].fields,
    actions: ref.current[resource].actions,
  }
}
