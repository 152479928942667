import React, {Fragment} from 'react'
import MuiToolbar from '@material-ui/core/Toolbar'
import {makeStyles} from '@material-ui/core/styles'
import {SaveButton} from 'react-admin'

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        justifyContent: 'space-between',
        paddingLeft: 16
    },
}))

export const CreateToolBar = ({
                                  handleSubmitWithRedirect,
                                  invalid,
                                  saving,
                              }) => {
    const classes = useStyles()
    return (
        <MuiToolbar className={classes.root}>
            <Fragment>
                <SaveButton
                    handleSubmitWithRedirect={handleSubmitWithRedirect}
                    invalid={invalid}
                    saving={saving}
                    redirect="list"
                    submitOnEnter={true}
                />
            </Fragment>
        </MuiToolbar>
    )
}
