import * as React from 'react'
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'
import {Drawer, useMediaQuery} from '@material-ui/core'
import {makeStyles, ThemeProvider} from '@material-ui/core/styles'
import lodashGet from 'lodash/get'
import {setSidebarVisibility, useLocale} from 'ra-core'

export const DRAWER_WIDTH = 260
export const CLOSED_DRAWER_WIDTH = 55

export const SideBar = (props) => {
  const {
    children,
    closedSize,
    size,
    classes: classesOverride,
    ...rest
  } = props
  const dispatch = useDispatch()
  const isXSmall = useMediaQuery(theme =>
    theme.breakpoints.down('xs')
  )
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const open = useSelector(
    state => state.admin.ui.sidebarOpen
  )
  useLocale() // force redraw on locale change
  const toggleSidebar = () => dispatch(setSidebarVisibility(!open))
  const {drawerPaper, fixed, ...classes} = useStyles({
    ...props,
    open,
  })

  return isXSmall ? (
    <Drawer
      variant="temporary"
      open={open}
      PaperProps={{
        className: drawerPaper,
      }}
      onClose={toggleSidebar}
      classes={classes}
      {...rest}
    >
      {children}
    </Drawer>
  ) : isSmall ? (
    <Drawer
      variant="permanent"
      open={open}
      PaperProps={{
        className: drawerPaper,
      }}
      onClose={toggleSidebar}
      classes={classes}
      {...rest}
    >
      <div className={fixed}>{children}</div>
    </Drawer>
  ) : (
    <Drawer
      variant="permanent"
      open={open}
      PaperProps={{
        className: drawerPaper,
      }}
      onClose={toggleSidebar}
      classes={classes}
      {...rest}
    >
      <div className={fixed}>{children}</div>
    </Drawer>
  )
}

SideBar.propTypes = {
  children: PropTypes.node.isRequired,
}

const useStyles = makeStyles(
  theme => ({
    root: {
      // height: 'calc(100vh - 3em)',
      backgroundColor: theme.palette.background.default,
    },
    docked: {},
    paper: {},
    paperAnchorLeft: {},
    paperAnchorRight: {},
    paperAnchorTop: {},
    paperAnchorBottom: {},
    paperAnchorDockedLeft: {},
    paperAnchorDockedTop: {},
    paperAnchorDockedRight: {},
    paperAnchorDockedBottom: {},
    modal: {},
    fixed: {
      position: 'fixed',
      // height: 'calc(100vh - 3em)',
      backgroundColor: theme.palette.background.default,
      overflowX: 'hidden',
      // hide scrollbar
      scrollbarWidth: 'none',
      msOverflowStyle: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    drawerPaper: {
      position: 'relative',
      width: (props) =>
        props.open
          ? lodashGet(theme, 'sidebar.width', DRAWER_WIDTH)
          : lodashGet(
          theme,
          'sidebar.closedWidth',
          CLOSED_DRAWER_WIDTH
          ),
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      backgroundColor: theme.palette.background.default,
      borderRight: 'none',
      [theme.breakpoints.only('xs')]: {
        marginTop: 0,
        height: '100vh',
        position: 'inherit',
        backgroundColor: theme.palette.background.default,
      },
      [theme.breakpoints.up('md')]: {
        border: 'none',
      },
      zIndex: 'inherit',
    },
  }),
  {name: 'RaSidebar'}
)

export const AppSideBar = (props) => {
  const settings = useSelector(({settings}) => settings)
  const theme = settings.themes[settings.current.navbar]

  return (
    <ThemeProvider theme={theme}>
      <SideBar {...props} />
    </ThemeProvider>
  )
}
