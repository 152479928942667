import CurrencyRubleIcon from '@mui/icons-material/CurrencyRuble'
import EuroOutlinedIcon from '@mui/icons-material/EuroOutlined';

export const currencyCodes = {
  'RUB': {
    symbol: '₽',
    icon: CurrencyRubleIcon,
  },
  'USD': {
    symbol: '$',
    icon: CurrencyRubleIcon
  },
  'EUR': {
    symbol: '€',
    icon: EuroOutlinedIcon
  },
  'AZN': {
    symbol: '₼',
    icon: CurrencyRubleIcon
  }
}

export const currencyUtils = {
  codeToSymbol(code) {
    if(currencyCodes[code]) {
      return currencyCodes[code]?.symbol
    }
    return code
  },
  beautifyAmount(amount) {
    let data = amount
    if(typeof amount !== "number") {
      data = Number(data)
    }
    return data.toFixed(2)
  },
  beautifyCurrencyAmount(amount, code) {
    return `${currencyUtils.codeToSymbol(code)} ${currencyUtils.beautifyAmount(amount)}`
  },
  BeautifyCurrencyAmountField: (props) => {
    const {record, codeField, amountField} = props
    const code = currencyUtils.codeToSymbol((record[codeField]))
    const value = Number(record[amountField])
    return `${code} ${value}`
  }
}

const BeautifyCurrencyAmountField = currencyUtils.BeautifyCurrencyAmountField

export {BeautifyCurrencyAmountField}
