import React from 'react'
import {useTranslate, MenuItemLink} from 'react-admin'
import VpnKeyIcon from "@mui/icons-material/VpnKey"
import LanguageSwitcher from "./components/LanguageSwitcher"
import UserMenu from "./components/UserMenu";

const UserMenuView = props => {
  const translate = useTranslate()

  return (
    <>
      <LanguageSwitcher />

      <UserMenu {...props}>
        {/*<MenuItemLink*/}
        {/*  to="/profile"*/}
        {/*  primaryText="Profile"*/}
        {/*  leftIcon={<SettingsIcon/>}*/}
        {/*/>*/}

        <MenuItemLink
          to="/change-password"
          primaryText={translate('actions.change_password')}
          leftIcon={<VpnKeyIcon />}
        />

      </UserMenu>
    </>
  )

}

export default UserMenuView
