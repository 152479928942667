import drfProvider from './drfProvider'
import {convertLegacyDataProvider, HttpError} from "ra-core"
import {reactAdminApiURL} from "../utils/apiURL";

const defaultProvider = drfProvider(reactAdminApiURL, async (url, options) => {

  if (!options.headers.has('Content-Type')) {
    if (options.body instanceof FormData) {
      // don't set Content-Type for multipart/form-data, it will setup with boundary by browser
    } else {
      options.headers.set('Content-Type', 'application/json')
    }
  }

  const response = await fetch(url, options)

  const {status} = response

  const body = await response.text()
  let json = {}
  try {
    json = JSON.parse(body)
  } catch (e) {
    // todo: empty response(DELETE) or errors?
  }

  if (status < 200 || status >= 300) {
    return Promise.reject(
      new HttpError(
        (json && json.message),
        status,
        json,
      )
    )
  }

  return {
    status,
    headers: options.headers,
    body,
    json,
  }
})

export const rawDataProvider = defaultProvider

const dataProvider = convertLegacyDataProvider(defaultProvider)

export default dataProvider
