import React from 'react'
import {AppBar} from 'react-admin'
import UserMenuView from './UserMenuView'
import {makeStyles, ThemeProvider} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import {useSelector} from "react-redux"

const useStyles = makeStyles(theme => ({
  title: {
    flex: `1 0`,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
  space: {
    flex: 1,
  },
}));

const CustomAppBar = props => {
  const classes = useStyles();
  const settings = useSelector(({settings}) => settings)
  const theme = settings.themes[settings.current.toolbar]

  return (
    <ThemeProvider theme={theme}>
      <AppBar
        {...props}
        userMenu={<UserMenuView/>}
      >
        <Typography
          variant="h6"
          color="inherit"
          className={classes.title}
          id="react-admin-title"
        />
        {/*<Logo type="text" />*/}
        <span className={classes.space} />
      </AppBar>
    </ThemeProvider>
  )
}

export default CustomAppBar
