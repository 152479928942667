import React from 'react'
import {Route} from 'react-router-dom'
import Drawer from '@material-ui/core/Drawer'
import {useRedirect} from 'react-admin'
import {CustomCreate} from "./CustomCreate";

export const CreateRoute = (props) => {
  const {baseURL, resourceName, resource, createLabel} = props
  const redirect = useRedirect()

  const drawerClose = () => redirect(baseURL)

  return (
    <React.Fragment key={resourceName}>
      <Route
        key={resourceName}
        path={`${baseURL}/${resourceName}/:id`}
      >
        {({match}) => {
          const isMatch = !!(match && match.params && match.params.id === 'create')
          return (
            <>
              <Drawer
                open={isMatch}
                anchor="right"
                onClose={drawerClose}
                hideBackdrop={props.hideBackdrop}
              >
                {isMatch ? (
                  <CustomCreate
                    {...props}
                    createLabel={createLabel}
                    resource={resource}
                    onCancel={drawerClose}
                    id={match.params.id}
                  />
                ) : null}
              </Drawer>
            </>
          )
        }}
      </Route>
    </React.Fragment>
  )
}
