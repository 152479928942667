import React from "react"
import {useSelector} from "react-redux"
import {ThemeProvider} from "@material-ui/core/styles"
import {SettingsSpeedDial} from "./SettingsSpeedDial"

export function Theming() {
  const settings = useSelector(({settings}) => settings)
  const theme = settings.themes[settings.current.toolbar]
  // const theme = settings.themes.defaultTheme

  return (
    <>
      <div>
      {/*<ThemeProvider theme={theme}>*/}
        <SettingsSpeedDial/>
      {/*</ThemeProvider>*/}
      </div>
    </>
  )
}
