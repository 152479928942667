import { Title, useTranslate } from 'react-admin'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { Box, Card, CardActions, CardContent, Chip, Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import React, { useEffect, useState } from "react";
import dataProvider from "../providers/dataProvider";
import { currencyUtils } from "../utils/currencyUtils";
import { useHistory } from "react-router-dom";


function Balance(props) {
  const {balance} = props
  const t = useTranslate()
  const history = useHistory();

  const balanceTitle = balance.account_type === "merchant_balance"
    ? t('pages.balances.merchant_balance_title')
    : t('pages.balances.merchant_rolling_reserve_title');

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" component="div" style={{opacity: .6}}>
        {balanceTitle}
          {" "}
          {balance.merchant.is_test && (
            <Chip
              variant="outlined"
              color="secondary"
              size="small"
              label={t(`resources.fbp_pricing/merchant.fields.is_test`)}
            />
          )}
        </Typography>

        <Box sx={{mt: 1}}>
          <Typography  variant="h4" component="div">
            {currencyUtils.codeToSymbol(balance.currency_code)}
            {' '}
            {currencyUtils.beautifyAmount(balance.balance)}
          </Typography>
        </Box>

      </CardContent>

      <CardActions>
        <Button
          size="small"
          color={"primary"}
          onClick={() => history.push(`/fbp_pricing/merchant/${balance.merchant.id}`)}
        >
          {balance.merchant.iname}
        </Button>
      </CardActions>
    </Card>
  )
}

export function BalancesPage() {
  const [balances, setBalances] = useState([])

  useEffect(() => {
    const res = Promise.all([
      dataProvider.getList('fbp_pricing/merchant', {}),
      dataProvider.getList('fbp_ledger/account', {}),
      dataProvider.getList('fbp_ledger/accounttype', {}),
      dataProvider.getList('fbp_pricing/merchantaccount', {}),
    ])
    res.then(data => {
      const [merchant, accounts, accountTypes, merchantAccount] = data

      const balanceTypes = accountTypes.data.filter(accountType =>
        accountType.iname === "merchant_balance" ||
        accountType.iname === "merchant_rolling_reserve"
      );

      const newBalances = accounts.data.filter(account =>
        balanceTypes.some(type => type.id === account.account_type)
      );

      newBalances.forEach(balance => {
        const link = merchantAccount.data.find(link => balance.id === link.account)
        balance.merchant = merchant.data.find(m => m.id === link.merchant)
        balance.account_type = balanceTypes.find(type => type.id === balance.account_type).iname
      })

      setBalances(newBalances)
    })
  }, [])

  return (
    <div>
      <Title title={BalancesPage.menuConfig.title} />

      <Grid container spacing={2}>

        {balances.map((balance, index) => (
          <Grid key={`key-${index}`} item xs={12} sm={6} md={6} lg={3} xl={2}>
            <Balance balance={balance} />
          </Grid>
        ))}

      </Grid>

    </div>
  )
}


BalancesPage.menuConfig = {
  menu: 'pages.balances.menu',
  title: 'pages.balances.title',
  icon: AccountBalanceWalletIcon,
  href: '/balances',
}