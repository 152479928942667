import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  useTranslate,
  SaveButton,
  Toolbar, BooleanInput, TextField, AutocompleteInput
} from "react-admin";
import {JsonEditor} from "../fields/JsonEditor/JsonEditor";
import {PermissionField} from "../PermissionField/PermissionField";
import {validation} from "../../utils/validators";
import {addPermissionLabel} from "../../utils/addPermissionLabel";
import {Box, Stack} from '@mui/material'
import DeleteButtonWithConfirmation from '../DeleteButtonWithConfirmation/DeleteButtonWithConfirmation'
import {makeStyles} from "@material-ui/core/styles";
import {EditReferenceFields} from "../EditReferenceFields/EditReferenceFields";
import i18nProvider from "../../providers/i18nProvider";
import {commission_objects_choices} from "../../configs/commissionObjects";
import {useResourcePermissions} from "../../utils/useResourcePermissions";

const {translate} = i18nProvider;

const commission = {
    createFormFields: [
        <AutocompleteInput
            variant="outlined"
            label={translate(`resources.fbp_pricing/productcommission.fields.commission_object`)}
            fullWidth
            source="commission_object"
            choices={commission_objects_choices}
            optionText="name"
            optionValue="iname"
        />,
        <TextInput variant="outlined" fullWidth label={translate(`resources.fbp_pricing/productcommission.fields.min_amount`)}
                   source="min_amount"/>,
        <TextInput variant="outlined" label={translate(`resources.fbp_pricing/productcommission.fields.fix_amount`)} fullWidth
                   source="fix_amount"/>,
        <TextInput variant="outlined" label={translate(`resources.fbp_pricing/productcommission.fields.percent`)} fullWidth
                   source="percent"/>,
        <ReferenceInput
            label={translate(`resources.fbp_pricing/productcommission.fields.commission_type`)}
            fullWidth source="commission_type" reference="fbp_pricing/commissiontype">
            <SelectInput variant="outlined" optionText="iname"/>
        </ReferenceInput>,
    ],
    editFormFields: [
        <AutocompleteInput
            label={translate(`resources.fbp_pricing/productcommission.fields.commission_object`)}
            fullWidth source="commission_object"
            choices={commission_objects_choices}
            optionText="name"
            optionValue="iname"
        />,
        <TextInput variant="outlined" fullWidth label={translate(`resources.fbp_pricing/productcommission.fields.min_amount`)}
                   source="min_amount"/>,
        <TextInput variant="outlined" label={translate(`resources.fbp_pricing/productcommission.fields.fix_amount`)} fullWidth
                   source="fix_amount"/>,
        <TextInput variant="outlined" label={translate(`resources.fbp_pricing/productcommission.fields.percent`)} fullWidth
                   source="percent"/>,
        <ReferenceInput
            label={translate(`resources.fbp_pricing/productcommission.fields.commission_type`)}
            fullWidth source="commission_type" reference="fbp_pricing/commissiontype">
            <SelectInput optionText="iname"/>
        </ReferenceInput>,
    ],
    tableFields: [
        <TextField key="commission_object" source="commission_object" sortable={false}/>,
        <TextField key="min_amount" source="min_amount" sortable={false}/>,
        <TextField key="fix_amount" source="fix_amount" sortable={false}/>,
        <TextField key="percent" source="percent" sortable={false}/>,
    ]
};

const useProductCommissionStyles = makeStyles({
    table: {
        maxWidth: 840,
        marginBottom: 6
    }
})

const PostCreateToolbar = (props) => {
    return (
        <Toolbar>
            <Box
                sx={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
            >
                <SaveButton {...props} />
                <DeleteButtonWithConfirmation {...props} />
            </Box>
        </Toolbar>
    );
};

const useStyles = makeStyles({
    data: {
      maxWidth: '840px'
    },
    switchButtonsContainer: {
        minWidth: 840,
        marginTop: 15,
        '& .switch-buttons-wrapper': {
            marginTop: 10,
            padding: '20px 16px 0',
            'box-shadow': '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
            'border-radius': 4
        },
        '& .switch-buttons-title': {
            fontSize: 24,
            marginBottom: 5,
        }
    }
});

const ProductEdit = (props) => {
  const translate = useTranslate()
  const {id, resource} = props
  const classes = useStyles();
  const productCommissionStyles = useProductCommissionStyles();
  const {actions: actionsInvoice} = useResourcePermissions(resource, 'is_available_for_invoice');
  const {actions: actionsWithdrawal} = useResourcePermissions(resource, 'is_available_for_withdrawal');

  return (
      <>
        <Edit
            title={`${translate('resources.fbp_pricing/product.edit')}`}
            {...props}
        >
          <SimpleForm variant="outlined"
              toolbar={<PostCreateToolbar/>}
          >
            <TextInput variant="outlined" source="name"/>

            <PermissionField
                source="merchant"
            >
              <ReferenceInput source="merchant" reference="fbp_pricing/merchant">
                <SelectInput variant="outlined" optionText="iname"/>
              </ReferenceInput>
            </PermissionField>

              <ReferenceInput source="status" reference="fbp_pricing/productstatus">
                  <SelectInput variant="outlined" optionText={(record) => {
                      return translate(`resources.fbp_pricing/productstatus.statuses.${record.iname}`)
                  }}/>
              </ReferenceInput>

            <EditReferenceFields
                classes={productCommissionStyles}
                label={translate(`resources.fbp_pricing/productcommission.name`, {smart_count: 1})}
                editLabel={translate(`resources.fbp_pricing/productcommission.edit`)}
                createLabel={translate(`resources.fbp_pricing/productcommission.create`)}
                baseProps={props}
                resourceName={"commission"}
                resource="fbp_pricing/productcommission"
                tableConfig={{
                  reference: "fbp_pricing/productcommission",
                  target: "merchant",
                  filter: {product: id},
                }}
                tableFields={commission.tableFields}
                createFormFields={commission.createFormFields}
                editFormFields={commission.editFormFields}
            />

              <PermissionField
                  source="data"
                  addLabel={addPermissionLabel(resource, "data")}
                  fullWidth
                  className={classes.data}
              >
                  <JsonEditor
                      validate={validation.composeValidators(validation.required, validation.json)}
                      source="data"
                  />
              </PermissionField>


            <div className={classes.switchButtonsContainer}>
              <div
                  className="switch-buttons-title">{translate(`resources.fbp_pricing/merchant.additional_settings`)}</div>
              <Box display={{xs: 'flex', sm: 'flex'}} className="switch-buttons-wrapper"
                   flexDirection="column">

                <Stack direction="row">
                    <BooleanInput resource={props.resource} source="is_available_for_invoice" disabled={!actionsInvoice || !actionsInvoice.includes('edit')} />
                    <BooleanInput resource={props.resource} source="is_available_for_withdrawal" disabled={!actionsWithdrawal || !actionsWithdrawal.includes('edit')} />
                </Stack>
              </Box>
            </div>
          </SimpleForm>
        </Edit>
      </>
  );
}

export default ProductEdit;
