import React from "react";
import {List, Datagrid, TextField, DateField, Filter, ReferenceField, useRedirect, RichTextField, FunctionField} from "react-admin";
import filterCreators from "../../utils/filterCreators";
import Drawer from "@material-ui/core/Drawer";
import {Route, useHistory} from "react-router-dom";
import {DateRangeInput} from "../inputs/DateRangeInput/DateRangeInput";
import i18nProvider from "../../providers/i18nProvider";
import {BeautifyCurrencyAmountField} from "../../utils/currencyUtils";
import {useResourcePermissions} from "../../utils/useResourcePermissions";
import { emitAction } from "../../utils/emitAction";
import DownloadField from "../fields/DownloadField/DownloadField";
import { TablePagination } from "../TablePagination/TablePagination";
import { status_choices } from "../../configs/withdrawalStatuses";
import StatusField from "../fields/StatusField/StatusField";
import {colorMapping} from "../../configs/BulkWithdrawalStatuses";

const dateFormat = (value) => {
  if (!value) {
    return value
  }
  return value.split('T')[0]
}

const dateParse = (value) => {
  if (!value) {
    return value
  }
  return value + 'T00:00:00+00:00'
}

const {translate} = i18nProvider

const BulkWithdrawalsFilters = (props) => (
  <Filter variant="outlined"  {...props}>
    {filterCreators.createSearchInput("search")}
    <DateRangeInput
      startText={translate('filters.created_date_from')}
      endText={translate('filters.created_date_to')}
      label={translate('filters.search_by_create_date')}
      source={"created_at"}
      format={dateFormat}
      parse={dateParse}
    />
    {filterCreators.createReferenceInput(
      "fbp_pricing/merchant",
      translate('filters.search_by_merchants'),
      "merchant",
      "iname",
      false,
    )}
    {filterCreators.createReferenceInput(
      "fbp_pricing/product",
      translate('filters.search_by_product'),
      "primary_product",
      "name",
      false,
    )}
    {filterCreators.createSelectInput(
      "status",
      translate('filters.search_by_status'),
      status_choices
    )}
    {/*{filterCreators.createDateInput({source: "created_at", format: dateFormat, parse: dateParse})}*/}
  </Filter>
);

const BulkWithdrawalsList = (props) => {
  const {resource} = props
  const redirect = useRedirect()
  const history = useHistory()
  // const {actions: paymentGateActions} = useResourcePermissions(resource, 'primary_payment_gate')

  const pathname = '/fbp_pricing/bulkwithdrawal'
  const basePath = `${pathname}${props.location.search}`

  // emitAction()

 return (
    <>
      <List
        {...props}
        empty={false}
        exporter={false}
        filters={<BulkWithdrawalsFilters/>}
        bulkActionButtons={false}
        sort={{ field: 'created_at', order: 'DESC' }}
        pagination={<TablePagination />}
        perPage={TablePagination.defaultPerPage}
      >
        <Datagrid>
          <TextField source="id" />
          <ReferenceField
            source="merchant"
            reference="fbp_pricing/merchant"
            link={false}
          >
            <TextField source="iname"/>
          </ReferenceField>

          {/*<DateField source="created_at"/>*/}
          {/*<DateField source="finished_at"/>*/}
          {/*<TextField source="status" />*/}
          {/*<TextField source="amount" />*/}

          <ReferenceField
            source="product"
            reference="fbp_pricing/product"
            link={false}
          >
            <TextField source="name"/>
          </ReferenceField>

          <DateField source="created_at" showTime/>
          <DateField source="finished_at" showTime/>

          <TextField source="progress_count"/>
          <RichTextField source="progress_amounts"/>

          <StatusField colorMap={colorMapping}  translationKey={`resources.fbp_pricing/bulkwithdrawal.statuses`} source="status"/>

          <DownloadField source="registry_url" label={translate('resources.fbp_pricing/bulkwithdrawal.download_registry')} />
          <DownloadField source="registry_result_url" label={translate('resources.fbp_pricing/bulkwithdrawal.download_registry_result')} />
          
        </Datagrid>
      </List>

    </>
  );
};

export default BulkWithdrawalsList;
