import React, {Fragment} from "react";
import {
    List,
    Datagrid,
    TextField,
    DateField,
    ReferenceField,
    Filter,
    BulkDeleteButton
} from "react-admin"
import {TablePagination} from "../TablePagination/TablePagination";
import filterCreators from "../../utils/filterCreators";
import i18nProvider from "../../providers/i18nProvider";
import {dateFormat, dateParse} from "../../utils/dateHelpers";
import {DateRangeInput} from "../inputs/DateRangeInput/DateRangeInput";
import {Button} from "@material-ui/core";
import SendIcon from '@mui/icons-material/Send';

const {translate} = i18nProvider;

const SendWebhooksButton = (props) => {
    return (
        <Button
        variant="text"
        startIcon={<SendIcon/>}
        >
        {translate('resources.fbp_webhook/webhook.send_webhooks_action')}
        </Button>
    )
}

const PostBulkActionButtons = props => (
    <Fragment>
        {/*<SendWebhooksButton {...props} />*/}
        {/* default bulk delete action */}
        <BulkDeleteButton {...props} />
    </Fragment>
);

const WebhookFilters = (props) => {
    return (
        <Filter variant="outlined"  {...props}>
            {filterCreators.createSearchInput("search")}
            {filterCreators.createReferenceInput(
                'fbp_pricing/merchant',
                translate('filters.search_by_merchants'),
                'merchant',
                'iname',
                false
            )}
            {filterCreators.createReferenceInput(
                'fbp_pricing/product',
                translate('filters.search_by_product'),
                'product',
                'name',
                false
            )}
            <DateRangeInput
                startText={translate('filters.created_date_from')}
                endText={translate('filters.created_date_to')}
                label={translate('filters.search_by_create_date')}
                source={"created_at"}
                format={dateFormat}
                parse={dateParse}
                alwaysOn={false}
            />
            {filterCreators.createArrayInput(
                "status",
                translate('filters.search_by_status'),
                [
                    {
                        key: 'preparing',
                        id: 'preparing',
                        name: 'preparing'
                    },
                    {
                        key: 'pending',
                        id: 'pending',
                        name: 'pending'
                    },
                    {
                        key: 'success',
                        id: 'success',
                        name: 'success'
                    },
                    {
                        key: 'process',
                        id: 'process',
                        name: 'process'
                    },
                    {
                        key: 'fail',
                        id: 'fail',
                        name: 'fail'
                    },
                ]
            )}
            {filterCreators.createNumberSearchInput({
                source: "response_http_status",
                label: 'filters.search_by_http_status'
            })}
        </Filter>
    )
}


const WebhookList = (props) => {
    return (
        <List
            {...props}
            empty={false}
            exporter={false}
            filters={<WebhookFilters/>}
            sort={{field: 'id', order: 'DESC'}}
            pagination={<TablePagination/>}
            perPage={TablePagination.defaultPerPage}
            bulkActionButtons={<PostBulkActionButtons />}
        >
            <Datagrid rowClick="edit">
                <TextField source="id"/>
                <DateField source="created_at"/>
                <ReferenceField source="merchant" reference="fbp_pricing/merchant" link={false}>
                    <TextField source="iname"/>
                </ReferenceField>
                <ReferenceField source="product" reference="fbp_pricing/product" link={false}>
                    <TextField source="name"/>
                </ReferenceField>
                <TextField source="url"/>
                <TextField source="status"/>
                <TextField source="response_http_status"/>
            </Datagrid>
        </List>
    )
}

export default WebhookList;