import React from "react";
import { useHistory } from "react-router-dom";
import { Create, SimpleForm, TextInput, AutocompleteInput, ReferenceInput, SelectInput } from "react-admin";

const MerchantComissionCreate = (props) => {
  const history = useHistory();

  const commission_object_choices = [
    {iname: "invoice", name: "invoice"},
    {iname: "withdrawal", name: "withdrawal"}
  ]

  return (
    <>
      <Create title="Create a Merchant Comission" {...props}>
        <SimpleForm variant="outlined" >
          <nav
            style={{ cursor: "pointer", marginBottom: "10px", color: "blue" }}
            onClick={() => history.push("/fbp_pricing/merchant")}
          >
            Back to merchants
          </nav>

          <AutocompleteInput source="commission_object" choices={commission_object_choices} optionText="name" optionValue="iname" />

          <TextInput variant="outlined" source="fix_amount" />

          <TextInput variant="outlined" source="percent" />

          <ReferenceInput source="commission_type" reference="fbp_pricing/commissiontype">
            <SelectInput optionText="iname" />
          </ReferenceInput>

          <ReferenceInput source="merchant" reference="fbp_pricing/merchant">
            <SelectInput optionText="iname" />
          </ReferenceInput>
        </SimpleForm>
      </Create>
    </>
  );
};

export default MerchantComissionCreate;
