import React from 'react'
import {
  useTranslate,
  SimpleForm,
  refreshView,
  useCreateController, useRedirect,
} from 'react-admin'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import {makeStyles} from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import {connect} from 'react-redux'
import {CreateToolBar} from "./CreateToolBar"
import {Box} from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '100vw',
    width: 500,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '12px 24px 0 24px',
  },
  // plane: {
  //   padding: '0 8px'
  // },
  inlineField: {
    display: 'inline-block',
    width: '50%',
  },
  form: {
    padding: '0 8px'
  }
}))

export const CreateController = ({...props}) => {
  const {baseURL, id, resource, createFormFields, onCancel, resourceName, createLabel} = props
  const redirect = useRedirect()

  const classes = useStyles()
  const translate = useTranslate()
  const redirectURL = baseURL

  const controllerProps = useCreateController({
    ...props,
    basePath: `${baseURL}/${resourceName}/${id}`,
    resource,
    id,
    undoable: false,
  })

  const save = controllerProps.save
  controllerProps.save = (data, redirectTo, _a = {}) => {
    _a.onSuccess = () => {
      props.refreshView()
      redirect(redirectURL)
    }
    save(data, redirectTo, _a)
  }

  const resourceField = /^.+\/(.+)$/.exec(props.baseProps.resource)[1]

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h6">
          {createLabel ? createLabel : `${translate('ra.action.create')} ${resourceName}`}
        </Typography>
        <IconButton onClick={onCancel}>
          <CloseIcon/>
        </IconButton>
      </div>

      <SimpleForm
          variant="outlined"
        initialValues={{[resourceField]: props.baseProps.id}}
        className={classes.form}
        basePath={controllerProps.basePath}
        record={controllerProps.record}
        save={controllerProps.save}
        version={controllerProps.version}
        resource={resource}
        toolbar={<CreateToolBar/>}
      >
          {createFormFields.map((Line, index) => (
            // <React.Fragment key={`${resourceName}-${index}`}>
              Line
            // </React.Fragment>
          ))}
      </SimpleForm>
    </div>
  )
}

const CustomCreate = connect(undefined, {refreshView})(CreateController)
export {CustomCreate}
