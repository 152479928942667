import React from "react";
import { Edit, SimpleForm, TextInput, AutocompleteInput, ReferenceInput, SelectInput } from "react-admin";

const MerchantComissionEdit = (props) => {
  const commission_object_choices = [
    {iname: "invoice", name: "invoice"},
    {iname: "withdrawal", name: "withdrawal"}
  ]

  return (
    <>
      <Edit title="Edit a Merchant Comission" {...props}>
        <SimpleForm variant="outlined">
          <AutocompleteInput source="commission_object" choices={commission_object_choices} optionText="name" optionValue="iname" />
          <TextInput variant="outlined" source="fix_amount" />
          <TextInput variant="outlined" source="percent" />
          <ReferenceInput source="commission_type" reference="fbp_pricing/commissiontype">
            <SelectInput optionText="iname" />
          </ ReferenceInput>
        </SimpleForm>
      </Edit>
    </>
  );
};

export default MerchantComissionEdit;
