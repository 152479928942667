import React, {useEffect} from "react"
import {useInput} from "ra-core"
import {ToggleButton, ToggleButtonGroup} from '@mui/material'
import BarChartIcon from '@mui/icons-material/BarChart'
import ShowChartIcon from '@mui/icons-material/ShowChart'
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {
    width: 96,
    margin: `16px 0 8px 0`,
  },
})

export const CustomInput = props => {
  const classes = useStyles()
  const {alwaysOn} = props

  const {
    input
  } = useInput({
    ...props,
  })

  const [value, setValue] = React.useState()

  const customChange = (e, newValue) => {
    if (newValue) {
      setValue(newValue)
      input.onChange(newValue)
    } else {
      input.onChange(value)
    }
  }

  useEffect(() => {
    setValue(input.value)
    input.onChange(input.value)
  }, [])

  return (
    <>
      <ToggleButtonGroup
        onChange={customChange}
        value={value}
        alwaysOn={alwaysOn}
        className={classes.root}
        exclusive
      >
        <ToggleButton value="bar" aria-label="left aligned">
          <BarChartIcon/>
        </ToggleButton>
        <ToggleButton value="line" aria-label="centered">
          <ShowChartIcon/>
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  )
}
