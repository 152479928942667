import {createTheme} from '@material-ui/core/styles'
import {createSlice} from '@reduxjs/toolkit'
import {themesConfig} from "../../configs/themesConfig"

// const getThemes = (state) => state.settings.themes
// const getMainThemeId = (state) => state.settings.current.theme.main
// const getNavbarThemeId = (state) => state.settings.current.theme.navbar
// const getToolbarThemeId = (state) => state.settings.current.theme.toolbar
// const getFooterThemeId = (state) => state.settings.current.theme.footer

// function generateMuiTheme(themes, id, direction) {
//   const data = _.merge({}, defaultThemeOptions, themes[id], mustHaveThemeOptions)
//   const response = createTheme(
//     _.merge({}, data, {
//       mixins: extendThemeWithMixins(data),
//       direction,
//     })
//   )
//   return response
// }
//
// export const selectFuseThemeById = (id) =>
//   createSelector([getThemes, getDirection], (themes, direction) =>
//     generateMuiTheme(themes, id, direction)
//   )
//
// export const selectContrastMainTheme = (bgColor) => {
//   function isDark(color) {
//     return getContrastRatio(color, '#ffffff') >= 3
//   }
//   return isDark(bgColor) ? selectMainThemeDark : selectMainThemeLight
// }
//
// export const selectMainTheme = createSelector(
//   [getThemes, getDirection, getMainThemeId],
//   (themes, direction, id) => generateMuiTheme(themes, id, direction)
// )
//
// export const selectMainThemeDark = createSelector(
//   [getThemes, getDirection],
//   (themes, direction, id) => generateMuiTheme(themes, 'mainThemeDark', direction)
// )
// export const selectMainThemeLight = createSelector(
//   [getThemes, getDirection],
//   (themes, direction, id) => generateMuiTheme(themes, 'mainThemeLight', direction)
// )
//
// export const selectNavbarTheme = createSelector(
//   [getThemes, getDirection, getNavbarThemeId],
//   (themes, direction, id) => generateMuiTheme(themes, id, direction)
// )
//
// export const selectToolbarTheme = createSelector(
//   [getThemes, getDirection, getToolbarThemeId],
//   (themes, direction, id) => generateMuiTheme(themes, id, direction)
// )
//
// export const selectFooterTheme = createSelector(
//   [getThemes, getDirection, getFooterThemeId],
//   (themes, direction, id) => generateMuiTheme(themes, id, direction)
// )

function createThemes() {
  const themes = {
    defaultTheme: createTheme(),
  }

  Object.entries(themesConfig).forEach(([key, value]) => {
    themes[key] = createTheme(value)
  })
  return themes
}

let storedThemes = {}
try {
  storedThemes = JSON.parse(localStorage.getItem('currentTheme'))
} catch (e) {
  // don't have themes
}

const initialState = {
  themesConfig,
  themes: createThemes(),
  current: {
    main: storedThemes?.main ?? 'default',
    navbar: storedThemes?.navbar ?? 'greyDark',
    toolbar: storedThemes?.toolbar ?? 'greyDark',
  },
}

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setTheme: (state, action) => {
      const {name, value} = action.payload
      state.current[name] = value
      localStorage.setItem('currentTheme', JSON.stringify(state.current))
    },
  },
})

export const {setTheme} = settingsSlice.actions

export const settingsReducer = settingsSlice.reducer
