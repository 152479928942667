import {createSlice} from '@reduxjs/toolkit'
import {fetchBalanceRoutine} from "./balanceSagas"

const balanceSlice = createSlice({
  name: 'balance',
  initialState: {
    loading: true,
    error: false,
    data: {},
  },
  reducers: {},
  extraReducers: {
    [fetchBalanceRoutine.REQUEST]: (state) => {
      state.loading = true
      state.error = false
      state.data = {}
    },
    [fetchBalanceRoutine.SUCCESS]: (state, action) => {
      state.data = action.payload
    },
    [fetchBalanceRoutine.FAILURE]: (state, action) => {
      state.error = true
    },
    [fetchBalanceRoutine.FULFILL]: (state, action) => {
      state.loading = false
    },
  }
})

export const balanceReducer = balanceSlice.reducer
