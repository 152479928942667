export const allActions = ['view', 'create', 'edit', 'delete', 'export']

export const roles = {
  admin: {
    allow: [
      {resource: '*', actions: '*'},
    ],
    deny: [
      {resource: 'features/balance', actions: ['view']},
    ],
  },
  merchant: {
    allow: [
      {resource: '*', actions: '*'},
    ],
    deny: [
      {resource: 'fbp_pricing/terminal', actions: ['view', 'edit', 'create', 'delete']},
      {resource: 'sbank/sbankuser', actions: ['view', 'edit', 'create', 'delete']},
      {resource: 'fbp_pricing/merchant', actions: ['create', 'delete']},
      {resource: 'fbp_pricing/merchant', fields: ['is_test', 'is_available_for_invoice', 'is_available_for_withdrawal'], actions: ['edit']},
      {resource: 'fbp_pricing/merchant', fields: ['data', 'logs'], actions: ['view']},
      {resource: 'fbp_pricing/merchantcommission', actions: ['edit', 'create', 'delete']},
      {resource: 'fbp_pricing/productcommission', actions: ['edit', 'create', 'delete']},
      {resource: 'fbp_pricing/terminalowner', actions: ['view', 'edit', 'create', 'delete']},
      {resource: 'fbp_pricing/terminal', actions: ['view', 'edit', 'create', 'delete']},
      {
        resource: 'fbp_pricing/invoice',
        fields: ['data', 'external_id', 'logs', 'primary_payment_gate'],
        actions: ['view']
      },
      {
        resource: 'fbp_pricing/withdrawalrequest',
        fields: ['data', 'external_id', 'logs', 'primary_payment_gate'],
        actions: ['view']
      },
      {
        resource: 'sbank/settlement',
        fields: ["merchant_order", "primary_product", "primary_product", 'data', 'external_id', 'logs', 'primary_payment_gate'],
        actions: ['view']
      },
      {resource: 'fbp_pricing/product', actions: []},
      {resource: 'fbp_pricing/product', fields: ['data', 'merchant', 'is_available_for_invoice', 'is_available_for_withdrawal'], actions: ['view', 'edit']},
      {resource: 'fbp_webhook/webhook', actions: ['view', 'edit', 'create', 'delete']},
    ]
  }
}
