import React from 'react'
import {makeStyles} from "@material-ui/core/styles";
import {currencyUtils} from "../../../utils/currencyUtils";
import {useRecordContext, useTranslate} from "react-admin";
import {LinearProgress} from "@material-ui/core";

const useStyles = makeStyles({
  box: {
    padding: `6px 8px`,
    border: `1px solid rgba(0, 0, 0, .23)`,
    borderRadius: 4,
  },
  label: {
    fontSize: '11px',
    marginBottom: 2,
  }
})

export const CurrencyField = ({label, source, resource, codeSource, ...props}) => {
  const translate = useTranslate()
  const classes = useStyles();

  const record = useRecordContext(props);
  const value = record ? record[source] : undefined
  const code = record ? record[codeSource] : undefined

  return (
    <div className={classes.box}>
      <div className={classes.label}>
        {translate(`resources.${resource}.fields.${source}`)}
      </div>
      {!value && <LinearProgress />}
      {value && <>{currencyUtils.codeToSymbol(code)} {Number(value)}</>}
    </div>
  )
}
