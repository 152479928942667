import * as React from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {Tooltip} from "@mui/material";

export const HelpInfo = props => {
  const {info} = props

  return (
    <div style={{cursor: "pointer"}}>
      <Tooltip arrow title={info} placement="right">
        <HelpOutlineIcon style={{opacity: .7}}/>
      </Tooltip>
    </div>
  )
}
