import React from "react";
import { useHistory } from "react-router-dom";
import { Create, SimpleForm, TextInput, ReferenceInput, SelectInput } from "react-admin";

const MerchantUrlCreate = (props) => {
  const history = useHistory();

  return (
    <>
      <Create title="Create a Merchant Url" {...props}>
        <SimpleForm variant="outlined" >
          <nav
            style={{ cursor: "pointer", marginBottom: "10px", color: "blue" }}
            onClick={() => history.push("/fbp_pricing/merchant")}
          >
            Back to merchants
          </nav>

          <ReferenceInput source="merchant_url_type" reference="fbp_pricing/merchanturltype">
            <SelectInput optionText="iname" />
          </ ReferenceInput>

          <TextInput variant="outlined" source="url" />

          <ReferenceInput source="merchant" reference="fbp_pricing/merchant">
            <SelectInput optionText="iname" />
          </ ReferenceInput>
        </SimpleForm>
      </Create>
    </>
  );
};

export default MerchantUrlCreate;
