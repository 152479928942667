export const colorMapping = {
    failed: 'error',
    pending: 'warning',
    complete: 'success',
    refunded: 'secondary',
    partial_complete: 'success',
}
export const paymentStatusIDs = {
  failed: 1,
  pending: 2,
  complete: 3,
  refunded: 4,
  new: 5,
  partial_complete: 6,
}
