import React, {useEffect} from 'react'
import {makeStyles} from "@material-ui/core/styles"
import {useDispatch, useSelector} from "react-redux"
import Typography from "@material-ui/core/Typography"
import {currencyUtils } from "../../utils/currencyUtils"
import {Box, Skeleton, Tooltip} from "@mui/material"
import {useTranslate} from "react-admin"
import {fetchBalanceRoutine} from "../../store/balance/balanceSagas"
import CreditCardIcon from '@mui/icons-material/CreditCard';

const useStyles = makeStyles({
  root: props => ({
    // height: props.open ? 100 : null,
  }),
  icon: props => ({
    position: "absolute",
    display: "inline-block",
    transition: 'transform ease .3s',
    transform: props.open ? `scale(0)` : `scale(1)`,
    transformOrigin: `0 50%`,
    opacity: .6,
    // color: `rgba(255, 255, 255, 0.7)`,
  }),
  text: props => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: props.open ? 100 : null,
    // alignItems: "center",
    // color: `rgba(255, 255, 255, 0.7)`,
    opacity: .8,
  }),
  title: {
    // color: `rgba(255, 255, 255, 0.9)`,
    opacity: .9,
  }
})

function Balance() {
  const open = useSelector((state) => state.admin.ui.sidebarOpen)
  const classes = useStyles({open})
  const t = useTranslate()

  const balance = useSelector(state => state.balance)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchBalanceRoutine.trigger())
  }, [])

  const balanceBlock = (
    <div className={classes.text}>
      {balance.loading || balance.error ? (
        <Box style={{opacity: 0.15}} >
          <Skeleton sx={{bgcolor: "primary.contrastText"}} variant="rectangular" width={90} height={24}/>
          <Box m={.5}/>
          <Skeleton sx={{bgcolor: "primary.contrastText"}} variant="rectangular" width={70} height={18}/>
        </Box>
      ) : (
        <>
          <Typography variant="h6" className={classes.title}>
            {t('menu.balance')}
          </Typography>
          <Typography>
            {currencyUtils.codeToSymbol(balance.data.currency_code)} {currencyUtils.beautifyAmount(balance.data.balance)}
          </Typography>
        </>
      )}
    </div>
  )

  return (
    <div className={classes.root}>

      <Tooltip
        placement="right"
        arrow
        title={balanceBlock}
      >
        <div className={classes.icon}>
          {balance.loading || balance.error ? (
            <Box style={{opacity: 0.15}} >
              <Skeleton sx={{bgcolor: "primary.contrastText"}} variant="rectangular" width={24} height={24}/>
            </Box>
          ) : (
            <>
              <CreditCardIcon />
              {/*<currencyCodes.RUB.icon />*/}
            </>
          )}
        </div>
      </Tooltip>

      {open && balanceBlock}
    </div>
  )
}

export default Balance
