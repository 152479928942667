import i18nProvider from "../providers/i18nProvider";

const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const nameRegExp = /^[-а-яА-Яa-zA-ZёЁ\s]{1,90}$/i

const startJSON = /^\s*{/i
const endJSON = /}\s*$/i

const {translate} = i18nProvider;

export const validation = {
  composeValidators: (...validators) => (value) => validators.reduce((error, validator) => error || validator(value), undefined),

  someFilled: values => Array.isArray(values) && values.some(value => value),
  required: value => value || value === 0 ? undefined : "Обязательное поле",
  minLength: min => value => typeof value !== "string" || value.length >= min ? undefined : `от ${min} символов`,
  maxInt: max => value => typeof value !== "string" || value <= max ? undefined : `Значение не должно быть больше ${max}`,
  minInt: min => value => value >= min ? undefined : `Значение должно быть не меньше ${min}`,
  isInteger: value => /^-?\d+$/.test(value) ? undefined : `Значение должно быть целым числом`,
  isLength: (value, message) => value.length ? undefined : message ? message : "required",
  isEmail: value => !emailRegExp.test(value) ? 'Неверный формат' : undefined,
  name: value => !!value && !nameRegExp.test(value) ? 'Неверный формат' : undefined,
  json: value => {
    if(typeof value === "object") {
      return undefined;
    }
    try {
      JSON.parse(value);
    } catch (e) {
      return translate('errors.json');
    }
    return undefined;
  },
  isJsonObject: value => {
    return validation.json(value) ?? !startJSON.test(value) ? 'Invalid JSON' : !endJSON.test(value) ? 'Invalid JSON' : undefined
  }
}
