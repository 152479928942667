import React, {useCallback, useRef} from 'react';
import {useTranslate, useLocale} from 'react-admin';
import PropTypes from 'prop-types';
import {useInput, FieldTitle} from 'ra-core';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import locales from "./locales";
import LocalizedUtils from "./LocalizedUtils";

export const Picker = ({PickerComponent, ...fieldProps}) => {
    const translate = useTranslate();
    const locale = useLocale();

    const {
        variant,
        options,
        label,
        source,
        resource,
        className,
        isRequired,
        providerOptions = {utils: LocalizedUtils, locale: locales[locale]},
        onChange
    } = fieldProps;

    const {input, meta} = useInput({source, onChange});

    const {touched, error} = meta;

    const handleChange = useCallback(value => {
        value ? input.onChange(value) : input.onChange(null);
    }, []);


    return (
        <div className="picker">
            <MuiPickersUtilsProvider {...providerOptions}>
                <PickerComponent
                    inputVariant={variant}
                    label={<FieldTitle
                        label={label}
                        source={source}
                        resource={resource}
                        isRequired={isRequired}
                    />}
                    margin="normal"
                    cancelLabel={translate('date_pickers.cancel')}
                    okLabel={translate('date_pickers.ok')}
                    clearLabel={translate('date_pickers.clear')}
                    error={!!(touched && error)}
                    helperText={touched && error}
                    className={className}
                    value={input.value ? new Date(input.value) : null}
                    onChange={date => handleChange(date)}
                    onBlur={() => input.onBlur(input.value ? new Date(input.value).toISOString() : null)}
                    {...options}
                />
            </MuiPickersUtilsProvider>
        </div>
    )
}

Picker.propTypes = {
    input: PropTypes.object,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    meta: PropTypes.object,
    options: PropTypes.object,
    resource: PropTypes.string,
    source: PropTypes.string,
    labelTime: PropTypes.string,
    className: PropTypes.string,
    providerOptions: PropTypes.shape({
        utils: PropTypes.func,
        locale: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    }),
};

Picker.defaultProps = {
    input: {},
    isRequired: false,
    meta: {touched: false, error: false},
    options: {},
    resource: '',
    source: '',
    labelTime: '',
    className: '',
};