import React from "react";
import {List, Datagrid, TextField, DateField, Filter, ReferenceField, useRedirect, RichTextField} from "react-admin";
import filterCreators from "../../utils/filterCreators";
import { useHistory } from "react-router-dom";
import { DateRangeInput } from "../inputs/DateRangeInput/DateRangeInput";
import i18nProvider from "../../providers/i18nProvider";
import {BeautifyCurrencyAmountField} from "../../utils/currencyUtils";
import { TablePagination } from "../TablePagination/TablePagination";
import { status_choices } from "../../configs/withdrawalStatuses";
import StatusField from '../fields/StatusField/StatusField';
import {refundStatusColorMapping} from "../../configs/refundStatuses";

const dateFormat = (value) => {
  if (!value) {
    return value
  }
  return value.split('T')[0]
}

const dateParse = (value) => {
  if (!value) {
    return value
  }
  return value + 'T00:00:00+00:00'
}

const {translate} = i18nProvider

const RefundsFilters = (props) => (
  <Filter variant="outlined"  {...props}>
    {filterCreators.createSearchInput("search")}
    <DateRangeInput
      startText={translate('filters.created_date_from')}
      endText={translate('filters.created_date_to')}
      label={translate('filters.search_by_create_date')}
      source={"created_at"}
      format={dateFormat}
      parse={dateParse}
    />
    {filterCreators.createReferenceInput(
      "fbp_pricing/merchant",
      translate('filters.search_by_merchants'),
      "merchant",
      "iname",
      false,
    )}
    {filterCreators.createReferenceInput(
      "fbp_pricing/product",
      translate('filters.search_by_product'),
      "endpoint",
      "name",
      false,
    )}
    {filterCreators.createSelectInput(
      "status",
      translate('filters.s'),
      status_choices
    )}
    {/*{filterCreators.createDateInput({source: "created_at", format: dateFormat, parse: dateParse})}*/}
  </Filter>
);

const RefundsList = (props) => {
  const {resource} = props
  const redirect = useRedirect()
  const history = useHistory()
  // const {actions: paymentGateActions} = useResourcePermissions(resource, 'primary_payment_gate')

  const pathname = '/fbp_pricing/refund'
  const basePath = `${pathname}${props.location.search}`

  // emitAction()

 return (
    <>
      <List
        {...props}
        empty={false}
        exporter={false}
        filters={<RefundsFilters/>}
        bulkActionButtons={false}
        sort={{ field: 'created_at', order: 'DESC' }}
        pagination={<TablePagination />}
        perPage={TablePagination.defaultPerPage}
      >
        <Datagrid>
          <TextField source="id" />
          <DateField source="created_at" showTime/>

          <ReferenceField source="merchant" reference="fbp_pricing/merchant" link={false}>
            <TextField source="iname"/>
          </ReferenceField>
          <ReferenceField source="endpoint" reference="fbp_pricing/product" link={false}>
            <TextField source="name"/>
          </ReferenceField>

          <ReferenceField source="invoice" reference="fbp_pricing/invoice" link={false}>
            <TextField source="id"/>
          </ReferenceField>

          <BeautifyCurrencyAmountField source="amount" amountField="amount" codeField="currency_code"/>

          <ReferenceField source="status" reference="fbp_pricing/refundstatus" link={false}>
            <StatusField colorMap={refundStatusColorMapping}  translationKey={`resources.fbp_pricing/invoice.statuses`} source="name"/>
          </ReferenceField>

        </Datagrid>
      </List>

    </>
  );
};

export default RefundsList;
