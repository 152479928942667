import React from "react";
import { List, Datagrid, TextField } from "react-admin";

const PaymentStatusList = (props) => {
  return (
    <List
      {...props}
      empty={false}
      exporter={false}
    >
      <Datagrid>
        <TextField source="id" sortable={false} />
        <TextField source="iname" sortable={false} />
        <TextField source="name" sortable={false} />
      </Datagrid>
    </List>
  );
};

export default PaymentStatusList;
