import i18nProvider from "../providers/i18nProvider";

const {translate} = i18nProvider

export const colorMapping = {
    new: 'secondary',
    accepted: 'warning',
    pending: 'warning',
    done: 'success',
    rejected: 'error',
    error: 'error'
}

export const statuses = [
    "new",
    "accepted",
    "pending",
    "done",
    "rejected",
    "error",
];

export const status_choices = statuses.map(status => ({
    id: status,
    get name() {
        return translate(`resources.fbp_pricing/withdrawalrequest.statuses.` + this.id);
    },
}));