import React, {useEffect, useState} from 'react'
import {Field, withTypes} from 'react-final-form'
import {useHistory} from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import {createTheme, makeStyles, ThemeProvider, useTheme} from '@material-ui/core/styles'
import {Notification, useRefresh} from 'react-admin'
import {useTranslate, useLogin, useNotify, useCheckAuth} from 'ra-core'
import Typography from "@material-ui/core/Typography"
import {useSelector} from "react-redux"

const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    background: theme.palette.background.default,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  card: {
    minWidth: 262,
    padding: `24px 24px 32px 24px`,
    marginTop: `calc((80vh - 360px) / 2)`,
  },
  icon: {
    // backgroundColor: theme.palette.primary.main,
    width: 60,
    height: 60,
  },
  hint: {
    marginTop: '1em',
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.grey[500],
  },
  input: {
    marginTop: 6,
  },
  actions: {
    marginTop: 6,
    padding: 0,
  },
  title: {
    marginBottom: 24,
  }
}))

const renderInput = ({
                       meta: {touched, error} = {touched: false, error: undefined},
                       input: {...inputProps},
                       ...props
                     }) => (
  <TextField
    variant="outlined"
    size="small"
    color="primary"
    error={!!(touched && error)}
    helperText={`${touched && error ? error : ' '}`}
    {...inputProps}
    {...props}
    fullWidth
  />
)

const {Form} = withTypes()

const LoginForm = () => {
  const [loading, setLoading] = useState(false)
  const translate = useTranslate()
  const classes = useStyles()
  const notify = useNotify()
  const login = useLogin()
  const checkAuth = useCheckAuth()
  const history = useHistory()
  const refresh = useRefresh()

  useEffect(() => {
    checkAuth({}, false)
      .then(() => {
        history.push('/')
      })
      .catch(() => {

      })
  }, [checkAuth, history])

  const handleSubmit = values => {
    setLoading(true)
    login(values, '/fbp_pricing/invoice')
      .then((x) => {
        history.push('/fbp_pricing/invoice')
        // setLoading(false)
        refresh()
      })
      .catch(error => {
        setLoading(false)
        notify(
          typeof error === 'string'
            ? error
            : typeof error === 'undefined' || !error.message
            ? 'ra.auth.sign_in_error'
            : error.message,
          'warning',
          {
            _:
              typeof error === 'string'
                ? error
                : error && error.message
                ? error.message
                : undefined,
          }
        )
      })
  }

  const validate = (values) => {
    const errors = {}
    if (!values.username) {
      errors.username = translate('ra.validation.required')
    }
    if (!values.password) {
      errors.password = translate('ra.validation.required')
    }
    return errors
  }

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      render={({handleSubmit}) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.main}>
            <Card className={classes.card}>

              <Typography variant="h6" align="center" className={classes.title}>
                {translate(`actions.login`)}
              </Typography>

              <div className={classes.form}>
                <div className={classes.input}>
                  <Field
                    autoFocus
                    name="username"
                    component={renderInput}
                    label={translate('ra.auth.username')}
                    disabled={loading}
                  />
                </div>
                <div className={classes.input}>
                  <Field
                    name="password"
                    component={renderInput}
                    label={translate('ra.auth.password')}
                    type="password"
                    disabled={loading}
                  />
                </div>
              </div>
              <CardActions className={classes.actions}>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={loading}
                  fullWidth
                >
                  {loading && (
                    <CircularProgress
                      style={{marginRight: 12}}
                      size={20}
                      thickness={4}
                    />
                  )}
                  {translate('ra.auth.sign_in')}
                </Button>
              </CardActions>
            </Card>
            <Notification/>
          </div>
        </form>
      )}
    />
  )
}

function Login() {
  const settings = useSelector(({settings}) => settings)
  const theme = settings.themes[settings.current.main]

  return (
    <ThemeProvider theme={{...theme}}>
      <LoginForm/>
    </ThemeProvider>
  )
}

export default Login
