import React from "react";
import {
    List,
    Datagrid,
    TextField,
    FunctionField,
    Filter,
    ReferenceField,
    TopToolbar,
    Button,
    SaveButton,
    FilterButton,
    SimpleForm,
    Toolbar,
    AutocompleteInput,
    DataProviderContext,
    BooleanInput,
    SelectInput,
    TextInput,
    DateField,
    useTranslate,
    useListController,
    ListContextProvider,
    DatagridBody,
    ListView,
    useNotify,
    useRefresh,
    useLocale
} from "react-admin";
import filterCreators from "../../utils/filterCreators";
import IconEvent from '@material-ui/icons/Event';
import SaveIcon from '@mui/icons-material/Save';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DownloadField from "../fields/DownloadField/DownloadField";
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {dateFormat, dateParse} from "../../utils/dateHelpers";
import i18nProvider from "../../providers/i18nProvider";
import {apiURL} from "../../utils/apiURL";
import {rawDataProvider} from "../../providers/dataProvider";
import {Box, CircularProgress} from "@mui/material"
import DateInput from "../inputs/DatePickers/DateInput";
import DateFnsUtils from "@date-io/date-fns";
import format from "date-fns/format";
import locales from "../inputs/DatePickers/locales";
import {DateRangeInput} from "../inputs/DateRangeInput/DateRangeInput";

const {translate} = i18nProvider
const indicatorStateCreating = 'creating';

const filterFields = [
        filterCreators.createReferenceInput(
            "fbp_pricing/merchant",
            translate('filters.search_by_merchants'),
            "merchant",
            "iname"
        ),
        <DateRangeInput
            startText={translate('filters.created_date_from')}
            endText={translate('filters.created_date_to')}
            label={translate('filters.search_by_create_date')}
            source={"created_at"}
            format={dateFormat}
            parse={dateParse}
        />
    ];

const RegistriesFilters = (props) => (
    <Filter variant="outlined" {...props}>
        {filterFields.map((filter) => filter)}
    </Filter>
);

const RegistryCreateIndicator = (props) => {
    return <CircularProgress {...props} />;
}

const RegistryRow = ({record, resource, id, onToggleItem, children, selected, selectable, basePath}) => {
    return (
        <TableRow key={id}>
            {/* data columns based on children */}
            {React.Children.map(children, field => {
                return (
                    <TableCell key={`${id}-${field.props.source}`}>
                        {indicatorStateCreating !== id ? (React.cloneElement(field, {
                            record,
                            basePath,
                            resource,
                        })) : field.props.source === 'registry_file' ? <RegistryCreateIndicator/> : null}
                    </TableCell>
                )
            })}
        </TableRow>
    )
};

const RegistryBody = props => <DatagridBody {...props} row={<RegistryRow/>}/>;

const registryTypeChoices = [
    {
    key: 'invoice',
    id: 'invoice',
    get name() {
      return translate(`resources.sbank/registryfile.types.payment`)
    },
  },
  {
    key: 'withdrawal',
    id: 'withdrawal',
    get name() {
      return translate(`resources.sbank/registryfile.types.withdrawal`)
    },
  },
];

const RegistriesList = (props) => {
    const dataProvider = React.useContext(DataProviderContext);
    const [open, setOpen] = React.useState(false);
    const [startDate, setStartDate] = React.useState("");
    const [endDate, setEndDate] = React.useState("");
    const [merchant, setMerchant] = React.useState("");
    const [type, setType] = React.useState(null);
    const [merchants, setMerchants] = React.useState([]);
    const [noSend, setNoSend] = React.useState(false);
    const [disabledButton, setDisabledButton] = React.useState(true);
    const [data, setData] = React.useState(() => {});
    const [ids, setIds] = React.useState(() => []);
    const [currentState, setCurrentState] = React.useState(null);
    const translate = useTranslate()
    const notify = useNotify();
    const refresh = useRefresh();
    const locale = useLocale();

    const controllerProps = useListController({...props, sort: {field: 'created_at', order: 'DESC'}});
    const { page, setPage, setSort, currentSort, setFilters, filter, filterValues } = controllerProps;

    React.useEffect(() => {
        setData(controllerProps.data);
        setIds(controllerProps.ids)
    }, [controllerProps.data, controllerProps.ids])


    React.useEffect(() => {
        dataProvider.getList("fbp_pricing/merchant", {
            filter: {},
            pagination: {
                page: 1,
                perPage: 25
            },
            sort: {
                field: "id",
                order: "DESC"
            }
        })
            .then(({data}) => {
                setMerchants(data);
            })
            .catch(error => {
                console.log(error);
            })
    }, []);

    React.useEffect(() => {
        if (merchant && startDate) {
            setDisabledButton(false);
        } else {
            setDisabledButton(true);
        }
    }, [merchant, startDate]);

    React.useEffect(() => {
        setCurrentState(ids.at(0));
    }, [ids]);

    const changeStartDate = (value) => {
        setStartDate(value ? format(value, 'yyyy-MM-dd') : null);
    };
    const changeEndDate = (value) => {
        setEndDate(value ? format(value, 'yyyy-MM-dd') : null);
    };
    const changeMerchant = (value) => {
        setMerchant(value);
    };

    const changeType = (e) => {
        setType(e.target.value);
    }

    const toggleRegistryRowCreatingIndicator = (state) => {
        let newData = {...data};
        let newIds = [...ids];

        if (state === 'error') {
           newData = controllerProps.data;
           newIds = controllerProps.ids;
        } else if(state === 'creating') {
            newData = {[indicatorStateCreating]: {}, ...newData};
            newIds = [indicatorStateCreating, ...newIds];
        }

        setData(newData);
        setIds(newIds);
    }

    const sendRegistryQuery = async () => {
        const {field, order} = currentSort;
        toggleRegistryRowCreatingIndicator('creating');
        notify('resources.sbank/registryfile.creating_registry_message', {type: 'success', autoHideDuration: 2000});

        try {
            const res = await rawDataProvider("CUSTOM_POST", `${apiURL}/create_registry`, {
                merchant_id: merchant,
                date_start: startDate,
                date_end: endDate,
                no_send: !noSend,
                registry_type: type
            });

            if (res && res.status === 200) {
                if (field !== 'created_at' || order !== 'DESC') {
                    setSort('created_at', 'DESC');
                }
                else if(page === 1) {
                    refresh();
                }
                else {
                    setPage(1);
                }

                notify('resources.sbank/registryfile.created_registry_message', {type: 'success'});
            }
        } catch (e) {
            notify('resources.sbank/registryfile.error_creating_registry_message', {type: 'warning'});
        }

        toggleRegistryRowCreatingIndicator('error');
        closeDialog();
    };

    const closeDialog = () => {
        setOpen(false);
        setMerchant("");
        setType(null);
        setStartDate("");
        setEndDate("");
        setDisabledButton(true);
        setNoSend(false)
    };


    const ListActions = (props) => (
            <TopToolbar {...props}>
                <FilterButton filters={filterFields} />
                <Button
                    onClick={() => {
                        setOpen(true)
                    }}
                    label={translate('resources.sbank/registryfile.create_form.create_registry_file')}
                >
                    <IconEvent/>
                </Button>
            </TopToolbar>
    );


    const DialogToolbar = (props) => (
        <Toolbar>
            <Button
                onClick={() => {
                    sendRegistryQuery()
                }}
                disabled={disabledButton}
                label={translate('resources.sbank/registryfile.create_form.confirm_create_registry_file')}
            >
                <SaveIcon/>
            </Button>
        </Toolbar>
    )

    return (
        <>
            <ListContextProvider value={controllerProps}>
                <ListView or {...props}
                          {...controllerProps}
                          ids={ids}
                          data={data}
                          empty={false}
                          exporter={false}
                          filters={<RegistriesFilters/>}
                          bulkActionButtons={false}
                          actions={<ListActions/>}
                          // sort={{field: 'created_at', order: 'DESC'}}
                          currentSort={currentSort}
                >
                    <Datagrid body={<RegistryBody/>}>
                        <TextField source="id" sortable={false}/>
                        <ReferenceField
                            source="merchant"
                            reference="fbp_pricing/merchant"
                            link={false}
                            sortable={false}
                        >
                            <TextField source="iname"/>
                        </ReferenceField>
                        <FunctionField
                            source='registry_type'
                            render={record => translate(`resources.sbank/registryfile.types.${record.registry_type}`)}
                            sortable={false}
                        />
                        <DateField source="created_at"/>
                        <DateField source="day"/>
                        <DateField source="day_to"/>
                        <DownloadField 
                            source="registry_file_url" 
                            sortable={false} 
                            label={translate('resources.sbank/registryfile.download_registry_file')}
                        />
                    </Datagrid>
                </ListView>
            </ListContextProvider>
            <Dialog
                open={open}
                onClose={() => closeDialog()}
            >
                <DialogTitle>
                    {translate('resources.sbank/registryfile.create_form.set_date_for_registry')}
                </DialogTitle>

                <DialogContent>
                    <SimpleForm
                        variant="outlined"
                        initialValues={{"no_send": false}}
                        toolbar={<DialogToolbar/>}
                    >
                        <AutocompleteInput
                            source="merchant"
                            label={translate('resources.fbp_pricing/merchant.name', {smart_count: 1})}
                            choices={merchants}
                            optionText="iname"
                            optionValue="id"
                            onChange={changeMerchant}
                        />
                        <SelectInput
                            source="registry_type"
                            label={translate('resources.sbank/registryfile.fields.registry_type')}
                            choices={registryTypeChoices}
                            onChange={changeType}
                        />
                        <DateInput
                            variant="outlined"
                            options={{ format: 'dd.MM.yyyy', ampm: false, clearable: true }}
                            label={translate('resources.sbank/registryfile.create_form.start')}
                            source="start"
                            onChange={changeStartDate}
                        />
                        <DateInput
                            variant="outlined"
                            options={{ format: 'dd.MM.yyyy', ampm: false, clearable: true }}
                            label={translate('resources.sbank/registryfile.create_form.end')}
                            source="end"
                            onChange={changeEndDate}
                        />
                        <BooleanInput
                            label={translate('actions.send_by_email')}
                            source="no_send"
                            onChange={(value) => setNoSend(value)}
                        />
                    </SimpleForm>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default RegistriesList;
