import React from "react"
import {
  Create,
  SimpleForm,
  ReferenceInput,
  TextInput,
  useTranslate,
  ArrayInput,
  SelectInput,
  ReferenceArrayInput
} from "react-admin"
import {terminalStatusesChoices} from "../../../configs/terminalStatuses"
import {JsonEditor} from "../../../components/fields/JsonEditor/JsonEditor"
import {validation} from "../../../utils/validators"
import {makeStyles} from "@material-ui/core/styles"

const useStyles = makeStyles({
  plane: {
    width: '100%',
    maxWidth: 840,
  },
})

const UserCreate = (props) => {
  const translate = useTranslate()
  const classes = useStyles()

  return (
    <Create
      title={`${translate('resources.fbp_pricing/terminalowner.create')}`}
      {...props}
    >
      <SimpleForm variant="outlined" >
        <TextInput variant="outlined" source="username"/>

        <TextInput variant="outlined" source="password"/>

      </SimpleForm>
    </Create>
  )
}

export default UserCreate
