import lists from "../../components/lists"
import React from "react"
import BulkWithdrawalEdit from "../../components/edit/BulkWithdrawalEdit"
import BulkWithdrawalCreate from "../../components/create/BulkWithdrawalCreate"
import TollIcon from '@mui/icons-material/Toll';

export const bulkwithdrawal = {
  name: 'fbp_pricing/bulkwithdrawal',
  icon: TollIcon,
  create: BulkWithdrawalCreate,
  edit: BulkWithdrawalEdit,
  list: lists.BulkWithdrawalsList,
}
