import lists from "../../components/lists"
import React from "react"
import RefundEdit from "../../components/edit/RefundEdit"
import RefundList from "../../components/lists/RefundsList"
// import RefundCreate from "../../components/create/RefundCreate"
import PaymentsIcon from '@mui/icons-material/Payments';

export const refund = {
  name: 'fbp_pricing/refund',
  icon: PaymentsIcon,
  // create: RefundCreate,
  edit: RefundEdit,
  list: RefundList,
}
