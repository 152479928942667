import React from "react"
import {
  Edit,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  NumberInput,
  useTranslate,
} from "react-admin"
import {validation} from "../../../utils/validators"
import {JsonEditor} from "../../../components/fields/JsonEditor/JsonEditor"
import {terminalStatusesChoices} from "../../../configs/terminalStatuses"
import {makeStyles} from "@material-ui/core/styles"

const useStyles = makeStyles({
    plane: {
        width: '100%',
        maxWidth: 840,
    },
    editor: {
        minWidth: 450,

        '& .MuiInputBase-root': {
            overflow: "hidden",
            resize: "both",
        }
    },
    simpleForm: {
    maxWidth: 400,
   }
})

const TerminalEdit = (props) => {
  const translate = useTranslate()
  const classes = useStyles()

  return (
    <>
      <Edit
        title={`${translate('resources.fbp_pricing/terminal.edit')}`}
        {...props}
      >
        <SimpleForm variant="outlined"
          className={classes.simpleForm}
        >
          {/*<Box fullWidth className={classes.plane}>*/}
            <ReferenceInput
              source="payment_gate"
              reference="fbp_pricing/paymentgate"
              fullWidth
            >
              <SelectInput optionText="iname"/>
            </ReferenceInput>

            <ReferenceInput
              source="terminal_owner"
              reference="fbp_pricing/terminalowner"
              fullWidth
            >
              <SelectInput allowEmpty optionText="iname" emptyText="wtf!"/>
            </ReferenceInput>

            <SelectInput
              fullWidth
              source="status"
              optionText="name"
              optionValue="id"
              choices={terminalStatusesChoices}
            />

            <NumberInput fullWidth source="current_day_amount" disabled={true}/>
            <NumberInput fullWidth source="current_day_count" disabled={true}/>
            <NumberInput fullWidth source="current_month_amount" disabled={true}/>
            <NumberInput fullWidth source="current_month_count" disabled={true}/>

            <NumberInput fullWidth source="min_amount"/>
            <NumberInput fullWidth source="limit_card_day_amount"/>
            <NumberInput fullWidth source="limit_card_day_count"/>
            <NumberInput fullWidth source="limit_card_withdrawal_day_amount"/>
            <NumberInput fullWidth source="limit_card_withdrawal_day_count"/>
            <NumberInput fullWidth source="limit_day_amount"/>
            <NumberInput fullWidth source="limit_day_count"/>
            <NumberInput fullWidth source="limit_month_amount"/>
            <NumberInput fullWidth source="limit_month_count"/>

            <JsonEditor
              overrideOptions={{
                 wordWrap: 'on',
              }}
              className={classes.editor}
              validate={validation.composeValidators(validation.required, validation.json)}
              label="Data"
              source="data"
            />

          {/*</Box>*/}
        </SimpleForm>
      </Edit>
    </>
  )
}

export default TerminalEdit
