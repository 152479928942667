import cubejs from "@cubejs-client/core"
import {apiURL} from '../utils/apiURL'

const auth = localStorage.getItem("auth_token")

export const cubejsApi = cubejs(
  `Token ${auth}`,
  {
    apiUrl: `${apiURL}/charts-api/v1`,
  }
);
